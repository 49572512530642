<template>
  <v-app id="inspire">
    <Vmenu />
    <div class="forme">
      <v-container>
        <div>
          <div class="mt-3 title font-weight-bold">
            {{ menus }}
          </div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row align="center" no-gutters>
              <v-col cols="12" sm="4">
                <v-autocomplete
                  v-model="selected.idcabang"
                  :items="datacabang"
                  item-text="desk"
                  item-value="idcabang"
                  cache-items
                  :rules="[(v) => !!v || 'Item is required']"
                  label="Daftar Cabang"
                  required
                  class="pa-1"
                  :search-input.sync="search"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="8"></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="nama"
                  :rules="NoRules"
                  label="Nama"
                  required
                  class="pa-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8"></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="password"
                  type="password"
                  label="Password"
                  class="pa-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8"></v-col> 
              <v-col cols="12" sm="4">
                <v-select
                  v-model="status"
                  :items="statuss"
                  label="Status" 
                  class="pa-1"
                ></v-select> 
              </v-col>
              <v-col cols="12" sm="8"></v-col>
              <v-col cols="12" sm="6" class="pa-1">
                <v-btn
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="simpandata"
                >
                  Simpan
                </v-btn>
                <v-btn color="success" class="ml-2" @click="userbaru">
                  Baru
                </v-btn>
                <v-btn color="error" class="ml-2" @click="batal">
                  Batal
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <div class="col"></div>
          <!-- <v-card flat>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Kelompok
                    </th>
                    <th class="text-left">
                      Nama
                    </th>
                    <th class="text-left">
                      Lihat Semua
                    </th>
                    <th class="text-left">
                      Lihat
                    </th>
                    <th class="text-left">
                      Tambah
                    </th>
                    <th class="text-left">
                      Edit
                    </th>
                    <th class="text-left">
                      Hapus
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in datatabel" :key="item.id">
                    <td>{{ item.jenis }}</td>
                    <td>{{ item.nama }}</td>
                    <td> 
                      <div class="text-center"> 
                        <input
                          type="checkbox" 
                          :id="item.id"  
                          v-if="item.lihatall == 'true'"
                          checked
                          @change="changedlhtall($event, item.id)" 
                        > 
                        <input
                          type="checkbox" 
                          :id="item.id" 
                          v-if="item.lihatall == 'false'" 
                          @change="changedlhtall($event, item.id)" 
                        >
                      </div>
                    </td>
                    <td> 
                      <div class="text-center">
                        <input
                          type="checkbox" 
                          :id="item.id" 
                          v-if="item.lihat == 'true'"
                          checked
                          @change="changedlht($event, item.id)" 
                        >
                        <input
                          type="checkbox" 
                          :id="item.id" 
                          v-if="item.lihat == 'false'" 
                          @change="changedlht($event, item.id)" 
                        >
                      </div>
                    </td>
                    <td> 
                      <div class="text-center">
                        <input
                          type="checkbox" 
                          :id="item.id" 
                          v-if="item.tambah == 'true'"
                          checked
                          @change="changedtambah($event, item.id)" 
                        >
                        <input
                          type="checkbox" 
                          :id="item.id" 
                          v-if="item.tambah == 'false'" 
                          @change="changedtambah($event, item.id)" 
                        >
                      </div>
                    </td>
                    <td> 
                      <div class="text-center">
                        <input
                          type="checkbox" 
                          :id="item.id" 
                          v-if="item.edit == 'true'"
                          checked
                          @change="changededit($event, item.id)" 
                        >
                        <input
                          type="checkbox" 
                          :id="item.id" 
                          v-if="item.edit == 'false'" 
                          @change="changededit($event, item.id)" 
                        >
                      </div>
                    </td>
                    <td> 
                      <div class="text-center">
                        <input
                          type="checkbox" 
                          :id="item.id" 
                          v-if="item.hapus == 'true'"
                          checked
                          @change="changedhapus($event, item.id)" 
                        >
                        <input
                          type="checkbox" 
                          :id="item.id" 
                          v-if="item.hapus == 'false'" 
                          @change="changedhapus($event, item.id)" 
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card> -->
        </div>
      </v-container>
    </div>
  </v-app>
</template>

<style scoped>
.forme {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: grey lighten-5;
}
</style>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from "axios";

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      valid: true,
      vid: -1,
      vjenis: "tambahdata",
      menus: "",
      selected: { idcabang: "-0000-", desk: "Pilih Cabang" },
      search: null,
      datacabang: [{ idcabang: "-0000-", desk: "Pilih Cabang" }],
      nama: "",
      password: "",
      status:'AKTIF',
      statuss:['AKTIF', 'TIDAK AKTIF'],
      datatabel: [],
      loading: false,
      NoRules: [(v) => !!v || "Field is required"],
    };
  },
  methods: {
    simpandata() {
      if (this.selected.idcabang == "-0000-") {
        alert("Data Cabang Harus Di isi");
      } else {
        this.loading = true;
        // kirim data
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP,
          branch: Constant.BRANCH_APP,
          jenis: this.vjenis,
          id: this.vid,
          nama: this.nama,
          cabang: this.selected.idcabang,
          password: this.password,
          status: this.status,
        };
        axios
          .post(Constant.API_ADDR + "pengguna/simpandata", datakirim)
          .then((response) => {
            if (response.data.status == 102) { 
              this.vid = response.data.id;
              this.datatabel = response.data.hakakses;
              this.loading = false;
            } else {
              this.loading = false;
              alert(response.data.message);
            }
          })
          .catch((errros) => {
            this.loading = false;
            console.log("error");
          });
        // this.loading = false;
      }
    },
    simpandatahakakses(jenis, id, value) 
    {
      this.loading = true;
      // kirim data
      const datakirim = { 
        idlog: Constant.IDLOG_APP,
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        branch: Constant.BRANCH_APP, 
        id: this.vid,
        cabang: this.selected.idcabang,
        nilai: value,
        vhakakses: jenis, 
        idakses: id,
      }; 
      axios
        .post(Constant.API_ADDR + "pengguna/simpanhakakses", datakirim)
        .then((response) => {
          if (response.data.status == 102) 
          {
            this.loading = false; 
          } 
          else 
          {
            this.loading = false;
            alert(response.data.message);
          }
        })
        .catch((errros) => {
          this.loading = false;
          console.log("error");
        }); 
    },
    userbaru() {
      this.datatabel = [];
      this.vid = -1;
      this.vjenis = "tambahdata";
      this.nama= "";
      this.password = "";
      this.status= 'AKTIF';
      this.selected.idcabang = 0;
      // this.$refs.form.reset();
    },
    batal() {
      this.$router.push({ name: "Pengguna" });
    },
    async getcabang(val) {
      // this.loading = true;
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP,
        branch: Constant.BRANCH_APP,
        search: val,
      };
      await axios
        .post(Constant.API_ADDR + "cabang/datacabang", datakirim)
        .then((response) => {
          this.datacabang = response.data.data;
        })
        .catch((errros) => {
          console.log("error");
        });
    },
    changedlhtall(e, id) 
    { 
      this.simpandatahakakses('lihatall', id, e.target.checked); 
    },
    changedlht(e, id) 
    { 
      this.simpandatahakakses('lihat', id, e.target.checked); 
    },
    changedtambah(e, id) 
    { 
      this.simpandatahakakses('tambah', id, e.target.checked); 
    },
    changededit(e, id) 
    { 
      this.simpandatahakakses('edit', id, e.target.checked); 
    },
    changedhapus(e, id) 
    { 
      this.simpandatahakakses('hapus', id, e.target.checked); 
    },
  },
  created() {
    Constant.IMENU = "Manage Pengguna";
    this.menus = Constant.IMENU;
    this.vid = this.$route.params.Id;
    this.vjenis = this.$route.params.Jenis;
    this.nama = this.$route.params.nama; 
    this.status = this.$route.params.status;
    this.cabang = this.$route.params.cabang;
    this.datatabel = this.$route.params.hakakses;
    this.selected.idcabang = this.$route.params.idcabang;
    this.getcabang(this.$route.params.cabang);
  },
  mounted() {},
  watch: {
    search(val) {
      this.isLoading = true;
      val && val !== this.getcabang(val);
    },
  },
};
</script>
