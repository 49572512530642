<template>
  <v-app id="inspire">
    <Vmenu /> 
    <div class="forme">
      <v-container>
        <div>
          <div class="mt-3 title font-weight-bold">
            {{ menus }}
          </div> 
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          > 
            <v-row align="center" no-gutters>
              <v-col cols="12"  sm="3" >
                <v-autocomplete 
                  v-model="selectedcabang.nomor"
                  :items="datacabang"
                  item-text="desk"
                  item-value="nomor"  
                  clearable 
                  label="Data Cabang"  
                  ref="refcabang"
                  class="pa-1" 
                ></v-autocomplete>
              </v-col> 
              <v-col cols="12"  sm="9"></v-col>
              <v-col cols="12" sm="4">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Setting Periode YYYY-MM-DD" 
                      readonly
                      v-bind="attrs"
                      v-on="on" 
                    ></v-text-field>
                  </template>
                  <v-date-picker 
                    v-model="date" 
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>   
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="nobukti"
                  :rules="NoRules"
                  label="Nobukti"
                  required
                  class="pa-1"
                  @focus="getnewcode"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="3">
                <v-autocomplete 
                  v-model="selectedpemasok.nomor"
                  :items="datapemasok"
                  item-text="desk"
                  item-value="nomor"  
                  cache-items 
                  label="Pemasok"
                  required  
                  class="pa-1" 
                ></v-autocomplete>  
              </v-col>   
              <v-col cols="12" sm="3">
                <v-text-field
                  type="number"
                  v-model="termin"
                  label="Termin"
                  class="pa-1"
                ></v-text-field>
              </v-col>  
              <v-col cols="12" sm="3"> 
                <v-text-field 
                  v-model="hutangawal" 
                  type="number"
                  label="Hutang Awal" 
                  class="pa-1"
                ></v-text-field>
              </v-col> 
              
              <v-col cols="12" sm="6" class="pa-1">
                <v-btn color="primary" 
                  :loading="loading"
                  :disabled="loading"
                  @click="simpandata">
                  Simpan
                </v-btn> 
                <v-btn color="error" class="ml-2" @click="batal">
                  batal
                </v-btn>  
              </v-col>  
            </v-row>
          </v-form>
        </div> 
      </v-container>
    </div>
     
  </v-app>
</template>

<style scoped>
  .forme {
    width: 100%;
    height: 100%; 
    position: relative; 
    background-color: grey lighten-5;
  } 
</style>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from "axios";  

export default { 
  components: {
    Vmenu,  
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      valid: true,
      loading: false,
      vid:-1,
      vjenis:'tambahdata',
      loading: false,
      menus:'', 
      selectedcabang: { nomor: "0", desk: "-Pilih Cabang-" }, 
      datacabang: [{nomor: "0", desk: "-Pilih Cabang-"}],
      selectedpemasok: { nomor: "0", desk: "0 || Pilih Pemasok" },
      datapemasok: [],
      nobukti:'',  
      termin:1,  
      hutangawal: 0,
      date: new Date().toISOString().substr(0, 10), 
      menu2: false,
      NumRules:[
        v => /^[0-9]+$/.test(v) || 'Must be Number Only' 
      ],
      NoRules: [
        v => !!v || 'Field is required', 
      ],    
      errors:{}
    };
  },
  methods: { 
    getnewcode()
    {
      this.loading = true;  
      if(this.vjenis == 'tambahdata')
      { 
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP, 
          branch: Constant.BRANCH_APP, 
          search: this.date
        };  
        axios
          .post(Constant.API_ADDR + "hutang/getnewcode", datakirim)
          .then((response) => {  
            if(response.data.status == 102)
            { 
              this.nobukti = response.data.data   
            }
            else
            {
              alert(response.data.message)
            }
            
          })
          .catch((errros) => {
            console.log("error");
          }); 
      }
      this.loading = false;  
    },
    simpandata()
    {
      this.loading = true;         
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP, 
        jenis: this.vjenis,  
        id: this.vid,
        tanggal:this.date,
        nofaktur: this.nobukti,
        term: this.termin,
        kodesuplier: this.selectedpemasok.kode,
        hutang_a: this.hutangawal,
        idcabang : this.selectedcabang.nomor 
      };  
      axios
        .post(Constant.API_ADDR + "hutang/simpandata", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.$refs.form.reset() 
            this.selectedpemasok.kode = "0"
            this.loading = false; 
          }
          else
          {
            alert(response.data.message)
            this.loading = false; 
          }
        })
        .catch((errros) => {
          console.log("error");
          this.loading = false; 
        });  
        
      
    },
    batal(){ 
      this.$router.push({ name: "Hutang Awal" }); 
    },
    async getmaster(){
      this.loading = true; 
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP 
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.datacabang = response.data.datacabang2 
            this.datapemasok = response.data.datasuplier 
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false; 
    }, 
  },
  created() {
    Constant.IMENU = "Manage Hutang Awal";
    this.getmaster(); 
    this.menus = Constant.IMENU;
    this.vid = this.$route.params.Id
    this.vjenis = this.$route.params.Jenis 
    this.selectedcabang.nomor = this.$route.params.idcabang     
    this.nobukti = this.$route.params.nobukti  
    this.date = this.$route.params.tanggal
    this.termin = this.$route.params.termin
    this.hutangawal = this.$route.params.hutangawal  
    this.selectedpemasok.nomor = this.$route.params.kodesuplier  
  },
  mounted() {},
  watch: {
    // searchpemasok (val)
    // { 
    //   this.isLoading = true
    //   val && val !== this.getpemasok(val) 
    // } 
  },
};
</script>
