<template>
  <v-app id="inspire">
    <Vmenu /> 
    <div class="forme">
      <v-container>
        <div>
          <div class="mt-3 sub-title font-weight-bold">
            {{ menus }}
            <v-btn icon class="ml-n2 pa-0">
              <v-avatar size="30" @click="getmaster" tile>
                <v-img :src="icondownload"></v-img>
              </v-avatar>
            </v-btn>   
          </div> 
           
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          > 
            <v-row align="center" no-gutters>  
              <v-col cols="12"  sm="3" >
                <v-autocomplete 
                  v-model="selectedcabang.nomor"
                  :items="datacabang"
                  item-text="desk"
                  item-value="nomor"  
                  clearable 
                  label="Data Cabang"  
                  ref="refcabang"
                  class="pa-1"
                  @input="getmaster(selectedcabang.nomor)"
                ></v-autocomplete>
              </v-col> 
              <v-col cols="12" sm="9"></v-col>
              <v-col cols="12" sm="3">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tgl"
                      label="Tanggal"  
                      persistent-hint 
                      v-bind="attrs" 
                      v-on="on"
                      class="pa-1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tgl"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu> 
              </v-col>
               
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="nobukti"
                  :rules="NoRules"
                  label="Nobukti" 
                  required 
                  @focus="getnewcode"
                  class="pa-1"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="6"></v-col> 
              <v-col cols="12" sm="3">
                <v-autocomplete 
                  v-model="selectedcustomer.nomor"
                  :items="datacustomer"
                  item-text="desk"
                  item-value="nomor"  
                  clearable 
                  label="Data Customer"  
                  ref="refcustomer"
                  class="pa-1"
                ></v-autocomplete>
              </v-col> 
              <v-col cols="12" sm="6"></v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="desk" 
                  label="Deskripsi"  
                  class="pa-1"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="6"></v-col>
              <v-col cols="12" sm="3">
                <v-btn 
                  block
                  tile
                  color="primary"  
                  @click="getfaktur"
                  class="pa-1"
                >
                  Faktur 
                </v-btn> 
              </v-col> 

              <v-col cols="12" sm="3"> 
                <p class="text-h6 font-weight-bold pa-1">
                  Total Faktur : {{ formatPrice(jumlahkredit) }}
                </p> 
              </v-col>
              
              <!-- tabeld faktur-->
              <v-col cols="12" class="pa-1">
                <v-card flat>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Nofaktur
                          </th>
                          <th class="text-left">
                            Total
                          </th>        
                          <th class="text-left">
                            Aksi
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="itemd in datatabeld"
                          :key="itemd.id"
                        >
                          <td>{{ itemd.nofaktur }}</td>
                          <td>{{ formatPrice(itemd.kredit) }}</td> 
                          <td>
                            <v-icon small class="mr-2" @click="editItemd(itemd)">
                              mdi-pencil
                            </v-icon>
                            <v-icon small @click="deleteItemd(itemd)">
                              mdi-delete
                            </v-icon>
                          </td> 
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>  
              </v-col>  
              <!-- -->
              <v-col cols="12" sm="3">
                <v-btn 
                  block
                  tile
                  color="primary"  
                  @click="dialogbayar = true"
                  class="pa-1"
                >
                  Bayar 
                </v-btn> 
              </v-col> 
              <v-col cols="12" sm="3"> 
                <p class="text-h6 font-weight-bold pa-1">                  
                  Total Bayar : {{ formatPrice(jumlahdebet) }}
                </p> 
              </v-col> 
              <!-- tabele -->
              <v-col cols="12" class="pa-1">
                <v-card flat>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Kode Bank
                          </th>
                          <th class="text-left">
                            Nama 
                          </th>
                          <th class="text-left">
                            Bayar
                          </th>
                          <th class="text-left">
                            Aksi
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="iteme in datatabele"
                          :key="iteme.id"
                        >
                          <td>{{ iteme.kode }}</td>
                          <td>{{ iteme.nama }}</td>
                          <td>{{ formatPrice(iteme.debet) }}</td>  
                          <td>
                            <v-icon small class="mr-2" @click="editIteme(iteme)">
                              mdi-pencil
                            </v-icon>
                            <v-icon small @click="deleteIteme(iteme)">
                              mdi-delete
                            </v-icon>
                          </td> 
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>  
              </v-col>  
              

              <v-col cols="12" sm="12" class="pa-1">
                <v-btn color="primary" small
                  :loading="loading"
                  :disabled="loading"
                  @click="simpandata">
                  Simpan
                </v-btn> 
                <v-btn color="error" small class="ml-2" @click="batal">
                  batal
                </v-btn> 
                <v-btn color="success" small class="ml-2" @click="tambahdata">
                  Tambah
                </v-btn>  
                <v-btn color="warning" small 
                  :loading="loadcetak"
                  :disabled="loadcetak"
                  class="ml-2" @click="cetak">
                  cetak
                </v-btn>
                
              </v-col>  
            </v-row>
          </v-form>

          <v-dialog
            v-model="dialogfaktur"
            persistent
            max-width="600px"
          > 
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete 
                        v-model="selectedfaktur.nomor"
                        :items="datafaktur"
                        item-text="desk"
                        item-value="nomor"   
                        :rules="[v => !!v || 'Item is required']"
                        label="Data Faktur"
                        required  
                      ></v-autocomplete> 
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        v-model="bayarfaktur"
                        label="Total Bayar" 
                      ></v-text-field>
                    </v-col>  
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogfaktur = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="simpandata"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialogbayar"
            persistent
            max-width="600px"
          > 
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete 
                        v-model="selectedbank.nomor"
                        :items="databank"
                        item-text="desk"
                        item-value="nomor"    
                        label="Pilih Bank" 
                      ></v-autocomplete> 
                    </v-col> 
                    <v-col cols="12">
                      <v-text-field
                        v-model="nobg"
                        label="Deskripsi" 
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"></v-col>
                    
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        v-model="debet"
                        label="Total Bayar" 
                      ></v-text-field>
                    </v-col> 
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogbayar = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="simpandata"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </div> 
      </v-container>
    </div>
     
  </v-app>
</template>

<style scoped>
  .forme {
    width: 100%;
    height: 100%; 
    position: relative; 
    background-color: grey lighten-5;
  } 
</style>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from "axios";

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      dialogfaktur:false,
      dialogbayar:false, 
      valid: true,
      menu1:false,
      mtgl2:false,
      loading:false,
      loadcetak:false, 
      vidh:-1,
      vidd:-1,
      vide:-1,
      vjenis:'tambahdata',
      menus:'',    
      selectedcabang: { nomor: "0", desk: "-Pilih Cabang-" }, 
      datacabang: [{nomor: "0", desk: "-Pilih Cabang-"}],
      tgl: new Date().toISOString().substr(0, 10), 
      nobukti:'',    
      selectedcustomer: { nomor: "0", desk: "-Pilih Customer-" }, 
      datacustomer: [{nomor: "0", desk: "-Pilih Customer-"}], 
      desk:'',  
      jumlahdebet:0,
      jumlahkredit:0, 
      selectedfaktur: { nomor: "0", desk: "-Pilih Faktur-" }, 
      datafaktur: [{nomor: "0", desk: "-Pilih Faktur-"}],  
      bayarfaktur:0,  
      selectedbank: { nomor : "0", desk: "-Pilih Bank-" },
      databank: [{nomor: "0", desk: "-Pilih Bank-"}],  
      nobg:'',
      tglbg:'', 
      debet:0, 
      datatabeld:[],
      datatabele:[],
      NoRules: [
        v => !!v || 'Field is required', 
      ],    
      iconedit: Constant.iconedit,
      iconhapus: Constant.icondelete,
      icondownload: Constant.icondownload, 
    };
  },
  methods: { 
    getnewcode()
    {
      this.loading = true;  
      if(this.vjenis == 'tambahdata')
      { 
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP, 
          branch: Constant.BRANCH_APP, 
          idcabang: this.selectedcabang.nomor,
          search: this.tgl
        };  
        axios
          .post(Constant.API_ADDR + "bayarp/getnewcode", datakirim)
          .then((response) => {  
            if(response.data.status == 102)
            {
              this.nobukti = response.data.data   
            }
            else
            { 
              this.$refs.refcabang.focus();
              alert(response.data.message)    
            }
            
          })
          .catch((errros) => {
            console.log("error");
          }); 
      }
      this.loading = false;  
    },
    async simpandata()
    { 
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP, 
        jenis: this.vjenis,
        idh: this.vidh,
        tanggal: this.tgl,
        nobukti: this.nobukti,  
        idcabang: this.selectedcabang.nomor,
        desk: this.desk,
        idd:this.vidd,
        nofaktur: this.selectedfaktur.nomor, 
        bayarfaktur: this.bayarfaktur,
        ide:this.vide,
        kodebank: this.selectedbank.nomor,
        kodecustomer: this.selectedcustomer.nomor,
        nobg: this.nobg,
        tglbg: this.tglbg,
        bayarbank: this.debet, 
      };  
      await axios
        .post(Constant.API_ADDR + "bayarp/simpandata", datakirim)
        .then((response) => {   
          if(response.data.status == 102 || response.data.status == '102' )
          {  
            this.vjenis = 'editdata'
            this.vidh = response.data.datah.id
            this.vidd = -1
            this.vide = -1
            this.datatabeld = response.data.datad 
            this.datatabele = response.data.datae  
            this.datafaktur = [];
            this.bayarfaktur = 0  
            this.debet = 0  
            this.selectedbank.nomor = 0;
            this.jumlahdebet = response.data.datah.jumlahdebet
            this.jumlahkredit = response.data.datah.jumlahkredit 
            this.loading = false; 
            alert(response.data.message) 
          }
          else
          {
            alert(response.data.message)
            this.loading = false; 
          } 
        })
        .catch((errros) => {
          console.log("error");
          this.loading = false; 
        });  
      this.dialogfaktur= false;
      this.dialogbayar = false; 
    },
    batal(){ 
      this.$router.push({ name: "Pembayaran Penjualan" }); 
    }, 
    async getmaster(){
      this.loading = true; 
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          {  
            this.databank = response.data.databank 
            this.datacabang = response.data.datacabang2  
            this.datacustomer = response.data.datacustomer
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false; 
    }, 
    tambahdetail(){
      this.selectedfaktur.nomor = '0'
      this.vidd = -1  
      this.dialogfaktur = true
    },
    tambahdetaile(){
      this.selectedbank.nomor = '0'
      this.vide = -1  
      this.dialogbayar = true
    },
    tambahdata(){
      this.vidh = -1
      this.vjenis = 'tambahdata' 
      this.nobukti = ''
      this.tgl = new Date().toISOString().substr(0, 10) 
      this.desk = "" 
      this.datatabeld = []
      this.datatabele= []
      this.selectedcabang.nomor = "0"
      this.selectedfaktur.nomor = "0" 
      this.selectedcustomer.nomor = "0"  
      this.jumlahdebet = 0
      this.jumlahkredit = 0
    },
    editItemd(item){
      this.getfaktur()
      this.selectedfaktur.nomor = item.nofaktur
      this.vidd = item.id 
      this.bayarfaktur = item.debet
      this.dialogfaktur = true
    },
    editIteme(item){       
      this.selectedbank.nomor = item.kodebayar
      this.vide = item.id 
      this.debet = item.kredit
      this.nobg = item.nobg
      this.tglbg = item.tglbg
      this.dialogbayar = true
    },
    deleteItemd(item){
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP,  
        idbayarh: item.idh, 
        idbayard: item.id,
        idcabang: this.selectedcabang.nomor
      };  
      axios
        .post(Constant.API_ADDR + "bayarp/hapusdatad", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            alert('sukses menghapus data') 
            this.datatabeld = response.data.datad 
            this.jumlahdebet = response.data.datah.jumlahdebet
            this.jumlahkredit = response.data.datah.jumlahkredit
            this.loading = false            
          }
          else
          {
            alert(response.data.message)
            this.loading = false;  
          }
        })
        .catch((errros) => {
          console.log("error");
          this.loading = false;  
        });   
    },
    deleteIteme(item){
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP,  
        idbayarh: item.idh, 
        idbayare: item.id,
        idcabang: this.selectedcabang.nomor
      };  
      axios
        .post(Constant.API_ADDR + "bayarp/hapusdatae", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            alert('sukses menghapus data')
            this.datatabele = response.data.datae 
            this.jumlahdebet = response.data.datah.jumlahdebet
            this.jumlahkredit = response.data.datah.jumlahkredit
            this.loading = false            
          }
          else
          {
            alert(response.data.message)
            this.loading = false;  
          }
        })
        .catch((errros) => {
          console.log("error");
          this.loading = false;  
        });   
    },
    cetak(){
      this.loadcetak = true;  
      if(this.vidh > 0) 
      {
        // kirim data
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP, 
          branch: Constant.BRANCH_APP,  
          idbayarp: this.vidh,  
        };  
        axios
        .post(Constant.API_ADDR + "bayarp/request", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.loadcetak = false  
            window.open(Constant.API_ADDR + "printpreviewfaktur/cetak/" + response.data.data) 
          }
          else
          {
            alert(response.data.message)
            this.loadcetak = false;  
          }
        })
        .catch((errros) => {
          console.log("error");
          this.loadcetak = false;  
        });   
      } 
      this.loadcetak = false;  
    },
    getfaktur(){ 
      this.loading = true; 
      this.datafaktur = [];
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP,  
        tgl: this.tgl,
        idcabang: this.selectedcabang.nomor, 
        idcustomer: this.selectedcustomer.nomor 
      };  
      axios
      .post(Constant.API_ADDR + "fakturjual/getdata", datakirim)
      .then((response) => { 
        if(response.data.status == 102)
        {
          this.datafaktur = response.data.data   
          this.dialogfaktur = true
        }
        else 
        {
          alert("gagal menampilkan data")
        } 
      })
      .catch((errros) => {
        console.log("error");
        alert("gagal menampilkan data")
      });
      this.loading = false;
    }, 
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  },
  created() {
    Constant.IMENU = "Manage Pembayaran Penjualan"; 
    this.getmaster(); 
    this.menus = Constant.IMENU;
    this.vidh = this.$route.params.Id
    this.vjenis = this.$route.params.Jenis 
    this.selectedcabang.nomor = this.$route.params.idcabang
    
    this.tgl = this.$route.params.tgl
    this.nobukti = this.$route.params.nobukti 
    this.selectedcustomer.nomor = this.$route.params.idcustomer
    this.desk = this.$route.params.desk  
    this.jumlahdebet = this.$route.params.jumlahdebet
    this.jumlahkredit = this.$route.params.jumlahkredit 
    this.datatabeld = this.$route.params.datatabeld 
    this.datatabele= this.$route.params.datatabele 
    this.selectedfaktur.nomor = "0" 
    this.selectedbank.nomor = "0" 
  },
  mounted() {},
  watch: { },
  computed: {}
};
</script>
