<template>
  <v-app id="inspire">
    <Vmenu />
    <v-container fluid>
      <v-card-title>
        {{ menu }}
      </v-card-title>
      <!-- filter -->
      <v-card flat>
        <v-row align="center" class="ml-3 mt-n4"> 
          <v-btn
            tile
            small
            color="primary"
            class="ml-1"
            @click="manage"
          >
            <v-avatar size="25" tile>
              <v-img :src="iconadd"></v-img>
            </v-avatar>
            Tambah
          </v-btn> 
          <v-btn
            tile
            small
            color="success"
            class="ml-1"
            @click="renewdata"
          >
            <v-avatar size="25" tile>
              <v-img :src="iconrefresh"></v-img>
            </v-avatar>
            Refresh
          </v-btn> 
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            no-gutters
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                tile
                small
                class="ml-1"
                color="error"                
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar size="25" tile>
                  <v-img :src="iconfilter"></v-img>
                </v-avatar>
                Filter
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-menu
                        ref="menudari"
                        v-model="menudari"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dari"
                            label="Dari"
                            hint="YYYY-MM-DD"
                            persistent-hint 
                            v-bind="attrs" 
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dari"
                          no-title
                          @input="menudari = false"
                        ></v-date-picker>
                      </v-menu> 
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-menu
                        ref="menusmp"
                        v-model="menusmp"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="sampai"
                            label="Sampai"
                            hint="YYYY-MM-DD"
                            persistent-hint
                            v-bind="attrs" 
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="sampai"
                          no-title
                          @input="menusmp = false"
                        ></v-date-picker>
                      </v-menu> 
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srnobukti"
                        label="Nobukti"
                        hint="Search Nobukti"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" >
                      <v-text-field
                        v-model="srdesk"
                        label="Deskripsi"
                        hint="Search Data Deskripsi"
                        persistent-hint
                      ></v-text-field>
                    </v-col> 
                    <v-col cols="12"  sm="6" >
                      <v-autocomplete 
                        v-model="selectedcabang.nomor"
                        :items="datacabang"
                        item-text="desk"
                        item-value="nomor"  
                        clearable 
                        label="Data Cabang"  
                      ></v-autocomplete>
                    </v-col>  
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="caridata"
                >
                  Search
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> 
        </v-row>         
      </v-card> 
      <!-- tabel -->
      <div class="col">
        <v-card flat>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    No
                  </th>
                  <th class="text-left">
                    Tgl
                  </th>
                  <th class="text-left">
                    No Retur
                  </th>
                  <th class="text-left">
                    Ex No Faktur
                  </th>
                  <th class="text-left">
                    Gudang
                  </th>
                  <th class="text-left">
                    Pemasok
                  </th>
                  <th class="text-left">
                    Nilai Faktur
                  </th>
                  <th class="text-left">
                    Deskripsi
                  </th>
                  <th class="text-left">
                    Pengguna
                  </th>
                  <th class="text-left">
                    Cabang
                  </th>
                  <th class="text-left">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in datatabel" :key="item.no">
                  <td>{{ item.no }}</td>
                  <td>{{ item.tgl }}</td>
                  <td>{{ item.nobukti }}</td>
                  <td>{{ item.exnobukti }}</td>
                  <td>{{ item.gudang }}</td>
                  <td>{{ item.suplier }}</td>
                  <td>{{ formatPrice(item.netto) }}</td>
                  <td>{{ item.desk }}</td>
                  <td>{{ item.username }}</td>
                  <td>{{ item.cabang }}</td>
                  <td>
                    <v-btn x-small icon max-width="15px" class="ma-1">
                      <v-avatar size="15" @click="editItem(item)" tile>
                        <v-img :src="iconedit"></v-img>
                      </v-avatar>
                    </v-btn>
                    <v-btn x-small icon max-width="15px" class="ma-1">
                      <v-avatar  size="15" @click="deleteItem(item)" tile>
                        <v-img :src="iconhapus"></v-img>
                      </v-avatar>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from "axios";

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      dialog: false,
      dari: new Date().toISOString().substr(0, 10), 
      sampai: new Date().toISOString().substr(0, 10), 
      menudari: false,
      menusmp: false,
      iconedit: Constant.iconedit,
      iconhapus: Constant.icondelete,
      iconadd: Constant.iconadd,
      iconrefresh: Constant.iconrefresh,
      iconfilter: Constant.iconfilter, 
      srnobukti: "", 
      srdesk: "",    
      selecteduser: { nomor: "0", desk: " -Pilih Pengguna-" }, 
      datauser: [{nomor: "0", desk: "-Pilih Pengguna-"}],   
      selectedcabang: { nomor: "0", desk: " -Pilih Cabang-" }, 
      datacabang: [{nomor: "0", desk: "-Pilih Cabang-"}],   
      datatabel: [],   
    };
  },
  methods: {
    async getmaster() {
      this.loading = true;
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP,
        branch: Constant.BRANCH_APP,
      };
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {
          if (response.data.status == 102) {
            this.datauser = response.data.datauser;
            this.datacabang = response.data.datacabang2;
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false;
    },
    async getdata() {
      this.loading = true;
      const datakirim = {
        idlog: Constant.IDLOG_APP,
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        branch: Constant.BRANCH_APP,
        dari: this.dari,
        sampai: this.sampai,
        srnobukti: this.srnobukti,
        sruser: this.selecteduser.nomor,
        srdesk: this.srdesk,
        srcabang: this.selectedcabang.nomor,
      
      };

      await axios
        .post(Constant.API_ADDR + "returbeli/daftarrbeli", datakirim)
        .then((response) => {
          if (response.data.status === 102) {
            this.datatabel = response.data.data;
            this.totalpage = response.data.totalpage;
            this.totaldata = response.data.totaldata;
          } else {
            if (response.data.status == 100) {
              alert(response.data.message);
              this.Constant.USER_APP = "";
              this.Constant.TOKEN_APP = "";
            }
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false;
    },
    editItem(item) {
      this.$router.push({
        name: "Retur Pembelian Manage",
        params: {
          Id: item.id,
          Jenis: "editdata",
          idcabang: item.idcabang,
          tgl: item.tgl,
          nobukti: item.nobukti,
          idexnobukti: item.idexnobukti,
          exnobukti: item.exnobukti,
          pjk: item.pjk,
          idgudang: item.idgudang,
          idsuplier: item.idsuplier,
          desk: item.desk,
          discount: item.discount,
          ppn: item.ppn,
          rpppn: item.rpppn,
          jumlahbrutto: item.jumlah,
          netto: item.netto,
          datatabel: item.datad,
        },
      });
    },
    deleteItem(item) {
      this.loading = true;
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP,
        idrbeli: item.id,
        idcabang: item.idcabang,
      };
      axios
        .post(Constant.API_ADDR + "returbeli/hapusrbeli", datakirim)
        .then((response) => {
          if (response.data.status == 102) {
            alert("sukses menghapus data");
            this.renewdata();
          } else {
            alert(response.data.message);
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false;
    },
    renewdata() {
      this.getdata();
    },
    manage() {
      if (Constant.USER_APP != "" && Constant.TOKEN_APP != "") {
        this.$router.push({
          name: "Retur Pembelian Manage",
          params: {
            Id: -1,
            Jenis: "tambahdata",
            idcabang: 0,
            tgl: new Date().toISOString().substr(0, 10),
            nobukti: "",
            idexnobukti: 0,
            exnobukti: "",
            pjk: 0,
            idgudang: 0,
            idsuplier: 0,
            desk: "",
            discount: 0,
            ppn: 0,
            rpppn: 0,
            jumlahbrutto: 0,
            netto: 0,
            datatabel: [],
          },
        });
      }
    },
    caridata() {
      this.dialog = false;
      this.getdata();
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  },
  created() {
    Constant.IMENU = "Retur Pembelian";
    this.dari = Constant.STARTDATE;
    this.getmaster();
    this.getdata();
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>
