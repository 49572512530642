<template>  
  <v-container fluid class="nota">    
    <v-row width=100% justify="start" class="ma-1">
      <v-btn depressed color="primary" small v-if="print === '0'" @click="printdata()">PRINT</v-btn> 
    </v-row>
    <div class="container-fluid">
      <v-row justify="center" width=100%> 
        <v-col cols="6" class="pa-0">
          <v-progress-linear
            color="teal accent-4"
            indeterminate
            rounded
            height="4" 
            :active="progress"
        ></v-progress-linear> 
        </v-col>
      </v-row>
    </div> 
    <v-row class="toko">
      <table width="100%" border="0" cellpadding='2' cellspacing="2" align="center" bgcolor="#ffffff" style="padding-top:0px;">
        <tbody>
          <tr>
            <td style="font-size: 12px;  
              line-height: 18px; 
              vertical-align: bottom; 
              text-align: center;">            
              <strong style="font-size:16px;">{{ office }}</strong> 
              <br>{{ alamat }} 
              <br>{{ telp }} 
            </td>
          </tr>
          <tr>
            <td height="2" colspan="0" style="border-bottom:1px solid #e4e4e4 "></td>
          </tr>
        </tbody>
      </table> 
    </v-row>
    <v-row class="header ml-1" width="100%">
      <table style="font-size: 12px; 
        color: #5b5b5b;  
        line-height: 18px; 
        vertical-align: bottom; 
        text-align: left;">
        <tr>
          <td>No. Bukti</td>
          <td>: </td>
          <td>{{ nobukti }}</td>
        </tr>
        <tr>
          <td>Tanggal</td>
          <td>: </td>
          <td>{{ tgl }}</td>
        </tr>
        <tr>
          <td>Bank</td>
          <td>: </td>
          <td>{{ bank }}</td>
        </tr>   
      </table>
    </v-row>  

    <v-row class="isi-nota ml-1"> 
      <table width="100%" 
        border="0" 
        cellpadding='0' 
        cellspacing="0" 
        align="center" 
        bgcolor="#ffffff" 
        style="padding-top:4px;">
        <tr>
          <th class="text-left">No Akun</th>
          <th class="text-left">Nama Akun</th>
          <th class="text-right">Jumlah</th>
        </tr>
        <tbody>
          <tr 
            v-for="(child, index) in datatabel"
            :key="index"
          > 
            <td class="text-left" style="vertical-align: top;">{{ child.nomor }} </td>
            <td class="text-left" style="vertical-align: top;">{{ child.namaperkiraan }} <br> {{ child.keterangan }} </td>  
            <td class="text-right" style="vertical-align: top;">{{ formatPrice(child.kredit) }}</td>   
          </tr> 
        </tbody> 
      </table>
    </v-row>
    
    <v-row>
      <table width="100%" border="0" cellpadding='2' cellspacing="2" align="center" bgcolor="#ffffff" 
        style="padding-top:4px;">
        <tbody>
          <tr>
            <td height="2" colspan="0" style="border-bottom:1px solid #e4e4e4 "></td>
          </tr> 
        </tbody>
      </table> 
    </v-row>

    <v-row class="total ml-1">
      <table width="100%" cellpadding="0" cellspacing="0" style="font-size: 12px;  
            line-height: 18px; 
            vertical-align: bottom; 
            text-align: left;">
        <tbody>            
          <tr>
            <td style="text-align: left; width:15%;"><strong>Total</strong></td>
            <td style="width:5%;">:</td>
            <td style="text-align: right; width:45%;"><strong>{{ total }}</strong></td>
          </tr>
          <!-- <tr>
            <td style="text-align: left; width:15%;"><strong>Disc</strong></td>
            <td style="width:5%;">:</td>
            <td style="text-align: right; width:45%;"><strong>Rp disc</strong></td>
          </tr>
          <tr>
            <td style="text-align: left; width:15%;"><strong>Total</strong></td>
            <td style="width:5%;">:</td>
            <td style="text-align: right; width:45%;"><strong>Rp Total</strong></td>
          </tr>  -->
        </tbody>
      </table>     
    </v-row>

    <v-row class="tutup">
      <table width="100%" border="0" cellpadding='2' cellspacing="2" align="center" bgcolor="#ffffff" style="padding-top:4px;">
          <tbody>
            <tr>
              <td height="2" colspan="0" style="border-bottom:1px solid #e4e4e4 "></td>
            </tr>
            <tr>
              <td style="font-size: 12px;  
                line-height: 18px; 
                vertical-align: bottom; 
                text-align: center;">            
                <strong style="font-size:14px;">-BUKTI KAS MASUK-</strong>
                <!-- <br>Barang Yang sudah dibeli tidak dapat dikembalikan  -->
                <br> --- {{ it }} ---
              </td>
            </tr>
          </tbody>
        </table> 
    </v-row>
    

    
  </v-container>  
</template> 

<style>   
    .nota 
    {
      /*max-width: 300px; */
      /*width: 58mm;*/
      width: 88mm;
      height: 100%;
      background-color: #ffffff;
      margin: 0;
      padding: 0;
      -webkit-font-smoothing: antialiased;

    }
    .isi-nota th
    {
      border-top: 1px solid #e4e4e4;
      border-bottom: 1px solid #e4e4e4;
      font-size: 12px; 
      color: #5b5b5b;  
      line-height: 18px; 
      vertical-align: bottom; 
      text-align: center;
    }
    .isi-nota td
    { 
      font-size: 12px; 
      color: #5b5b5b;  
      line-height: 18px; 
      vertical-align: bottom;  
    } 
    
</style>



<script>
import Constant from "@/components/Constant.vue";
import axios from "axios";  

export default { 
  data: () => ({ 
    token: "",
    datatabel : [],
    office: "", 
    telp: "",
    alamat: "",
    total: 0,
    nobukti: "",
    tgl: "",
    bank: "",
    it: "",     
    print:0,
    progress: true
  }),
  methods: { 
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getclassheader(level){ 
      if(level != 4)
      {
        return "text-caption font-weight-black" 
      }
      return "text-caption font-weight-regular" 
    },
    async getdata(){  
      await axios 
        .post(localStorage.getItem("API_ADDR") + "kasm/viewkasm", {token: this.token})
        .then((response) => {
          if (response.data.status === 102 || response.data.status === "102") 
          {
            this.datatabel = response.data.data       
            this.office = response.data.office
            this.telp = response.data.telp
            this.alamat = response.data.alamat
            this.nobukti = response.data.nobukti
            this.tgl = response.data.tgl
            this.it = response.data.it
            this.bank = response.data.bank
            this.total = response.data.total
            this.progress = false
            this.cetakdata()
          } else {
            this.progress = false
            console.log("error");  
          }
        })
        .catch((errros) => {
          this.progress = false
          console.log("error"); 
        });
       
    },
    editItem(item){

    },
    printdata()
    { 
      this.print = 1 
      this.cetakdata() 
    },
    cetakdata(){
      if(this.print == 1)
      { 
        this.print = 0        
        setTimeout(function () {
          window.print()
          window.onafterprint = window.close()
          this.print = 0
          window.onfocus = function () { setTimeout(function () { window.close(); }, 500); } 
        }, 500);  
      }   
    }
  },
  created() 
  {
    Constant.IMENU = 'KasMPrint';
    this.token = this.$route.query.noinv 
    this.print = this.$route.query.print 
    this.getdata() 
  },

};
</script>

 