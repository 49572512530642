<template>
  <v-app id="inspire">
    <Vmenu /> 
    <div class="forme">
      <v-container>
        <div>
          <div class="mt-3 title font-weight-bold">
            {{ menus }}
          </div> 
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          > 
            <v-row align="center" no-gutters>   
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="nama" 
                  label="Nama"
                  required
                  class="pa-1"
                  ref="nama"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="alamat" 
                  label="Alamat"
                  required
                  class="pa-1"
                  ref="alamat"
                ></v-text-field>
              </v-col> 
              
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="telp" 
                  label="Telp"
                  required
                  class="pa-1"
                  ref="telp"
                ></v-text-field>
              </v-col>   
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="pass" 
                  label="Password"
                  type="password"
                  required
                  class="pa-1"
                  ref="pass"
                ></v-text-field>
              </v-col>  
              <v-col cols="12" sm="6" class="pa-1">
                <v-btn color="primary" 
                  :loading="loading"
                  :disabled="loading"
                  @click="simpandata">
                  Simpan
                </v-btn> 
                <v-btn color="error" class="ml-2" @click="batal">
                  batal
                </v-btn>  
              </v-col>  
            </v-row>
          </v-form>
        </div> 
      </v-container>
    </div>
     
  </v-app>
</template>

<style scoped>
  .forme {
    width: 100%;
    height: 100%; 
    position: relative; 
    background-color: grey lighten-5;
  } 
</style>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from "axios";

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      loading:false,
      valid: true,
      vid:-1,
      vjenis:'tambahdata',
      menus:'',             
      nama:'',
      alamat:'',
      pass:'',     
      telp:"",
    };
  },
  methods: { 
    simpandata()
    {
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        jenis: this.vjenis,
        id: this.vid,
       
        telp: this.telp,
        nama: this.nama,
        alamat: this.alamat,
        pass: this.pass, 
      };  
      axios
        .post(Constant.API_ADDR + "cabang/simpandata", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.vid            = -1
            this.vjenis         = 'tambahdata'
            this.loading        = false; 
            this.bersih()
          }
          else
          {
            alert(response.data.message)
            this.loading = false; 
          } 
        })
        .catch((errros) => {
          this.loading = false; 
          console.log("error");
        });   
    },
    batal(){ 
      this.$router.push({ name: "Cabang" }); 
    },  
    bersih(){ 
      this.nama = ''
      this.alamat = '' 
      this.telp = ''
      this.pass = ''
    }
  },
  created() {
    Constant.IMENU = "Manage Cabang"; 
    this.menus = Constant.IMENU;
    this.vid = this.$route.params.Id
    this.vjenis = this.$route.params.Jenis     
    
    this.nama = this.$route.params.nama
    this.telp = this.$route.params.telp
    this.alamat = this.$route.params.alamat 
  },
  mounted() {},
  watch: {},
};
</script>
