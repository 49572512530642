<template>
  <v-app id="inspire">
    <Vmenu /> 
    <div class="forme">
      <v-container>
        <div>
          <div class="mt-3 title font-weight-bold">
            {{ menus }}
          </div> 
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          > 
            <v-row align="center" no-gutters>  
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="kelompok" 
                  label="Kelompok"
                  required
                  class="pa-1"
                  ref="kelompok"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="jenisstok"
                  :items="jeniss" 
                  label="Jenis" 
                  class="pa-1"
                ></v-select> 
              </v-col>
              <v-col cols="12" sm="4"></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="nama" 
                  label="Nama"
                  required
                  class="pa-1"
                  ref="nama"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="kode" 
                  label="Kode"
                  @focus="getnewcode"
                  required
                  class="pa-1"
                  ref="kode"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="4"></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="satuankecil" 
                  label="Satuan kecil"
                  required
                  class="pa-1"
                  ref="satuankcl"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="satuantengah" 
                  label="Satuan Tengah"
                  required
                  class="pa-1"
                  ref="satuantgh"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="isitengah" 
                  label="Isi Tengah"
                  required
                  class="pa-1"
                  ref="isi"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="hargabeli" 
                  type="number"
                  label="Harga Beli" 
                  class="pa-1"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="hargajual" 
                  type="number"
                  label="Harga Jual" 
                  class="pa-1"
                ></v-text-field>
              </v-col>  
              
              <v-col cols="12" sm="6" class="pa-1">
                <v-btn color="primary" 
                  :loading="loading"
                  :disabled="loading"
                  @click="simpandata">
                  Simpan
                </v-btn> 
                <v-btn color="error" class="ml-2" @click="batal">
                  batal
                </v-btn>  
              </v-col>  
            </v-row>
          </v-form>
        </div> 
      </v-container>
    </div>
     
  </v-app>
</template>

<style scoped>
  .forme {
    width: 100%;
    height: 100%; 
    position: relative; 
    background-color: grey lighten-5;
  } 
</style>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from "axios";

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      loading:false,
      valid: true,
      vid:-1,
      vjenis:'tambahdata',
      menus:'', 
      selected: { nomor: "0", desk: "0 || Pilih Akun Perkiraan" }, 
      dataakunbeli: [{nomor: "0", desk: "0 || Pilih Akun Perkiraan"}],  
      dataakunjual: [{nomor: "0", desk: "0 || Pilih Akun Perkiraan"}],  
      search: null, 

      kelompok:'',
      kode:'',
      nama:'',
      jeniss:['STOK', 'NON STOK'],
      jenisstok:'STOK', 

      hargabeli:0,
      hargajual:0,
      satuankecil:"",
      satuantengah:"",
      isitengah:"",

       
    };
  },
  methods: { 
    getnewcode()
    {
      this.loading = true;  
      if(this.vjenis == 'tambahdata')
      { 
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP, 
          search: this.nama
        };  
        axios
          .post(Constant.API_ADDR + "barang/getnewcode", datakirim)
          .then((response) => {
            if(response.data.status == 102)
            {  
              this.kode = response.data.data  
            }
            else
            { 
              alert(response.data.message)         
              this.$refs.nama.focus()
            }   
          })
          .catch((errros) => {
            console.log("error");
          }); 
      }
      this.loading = false;  
    },
    simpandata()
    {
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        jenis: this.vjenis,
        id: this.vid,
       
        kode: this.kode,
        nama: this.nama,
        kelompok: this.kelompok,
        jenisstok: this.jenisstok,
        satuankecil: this.satuankecil,
        satuantengah: this.satuantengah,
        isitengah: this.isitengah,
        stokminimal: this.stokminimal,
        hargabeli: this.hargabeli,
        hargajual: this.hargajual,

        // norekbeli: this.norekbeli,
        // norekjual: this.norekjual, 
      };  
      axios
        .post(Constant.API_ADDR + "barang/simpandata", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.vid            = -1
            this.vjenis         = 'tambahdata'
            this.loading        = false; 
            this.bersih()
          }
          else
          {
            alert(response.data.message)
            this.loading = false; 
          } 
        })
        .catch((errros) => {
          this.loading = false; 
          console.log("error");
        });   
    },
    batal(){ 
      this.$router.push({ name: "Barang" }); 
    }, 
    async getmaster(){
      this.loading = true; 
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP 
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.dataakun = response.data.dataakun4
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false; 
    },
    bersih(){
      this.kelompok = ''
      this.jenisstok = 'STOK'
      this.kode = ''
      this.nama = ''
      this.satuankecil = ''
      this.satuantengah = ''
      this.isitengah = 1
      this.hargabeli = 0
      this.hargajual = 0
    }
  },
  created() {
    Constant.IMENU = "Manage Barang";
    // this.getmaster(); 
    this.menus = Constant.IMENU;
    this.vid = this.$route.params.Id
    this.vjenis = this.$route.params.Jenis 
    
    this.kelompok = this.$route.params.kelompok
    this.jenisstok = this.$route.params.jenisstok
    this.nama = this.$route.params.nama
    this.kode = this.$route.params.kode
    this.satuankecil = this.$route.params.satuankecil
    this.satuantengah = this.$route.params.satuantengah
    this.isitengah = this.$route.params.isitengah
    this.hargabeli = this.$route.params.hargabeli
    this.hargajual = this.$route.params.hargajual 
  },
  mounted() {},
  watch: {},
};
</script>
