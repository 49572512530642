import Vue from "vue";
import VueRouter from "vue-router"; 
import Vueexam from "../components/HelloWorld.vue"; 
import Login from "../components/Login.vue"; 
import Dashboard from "../components/Dashboard.vue"; 

import Bank from "../components/bank/Daftarbank.vue"; 
import BankManage from "../components/bank/Managebank.vue";

import Barang from "../components/barang/Daftarbarang.vue"; 
import BarangManage from "../components/barang/Managebarang.vue";

import Gudang from "../components/gudang/Daftargudang.vue"; 
import GudangManage from "../components/gudang/Managegudang.vue";

import Cabang from "../components/cabang/Daftarcabang.vue"; 
import CabangManage from "../components/cabang/Managecabang.vue";

import Suplier from "../components/suplier/Daftarsuplier.vue"; 
import SuplierManage from "../components/suplier/Managesuplier.vue";
import Salesman from "../components/salesman/Daftarsalesman.vue"; 
import SalesmanManage from "../components/salesman/Managesalesman.vue";

import TipeAktiva from "../components/tipeaktiva/Daftartipe.vue"; 
import TipeAktivaManage from "../components/tipeaktiva/Managetipe.vue";

import KelompokAktiva from "../components/kelompokaktiva/Daftarkelompok.vue"; 
import KelompokAktivaManage from "../components/kelompokaktiva/Managekelompok.vue";

// import Customer from "../components/customer/Daftarcustomer.vue"; 
import Customer from "../components/customer2/Daftarcustomer.vue"; 
import CustomerManage from "../components/customer2/Managecustomer.vue";

import Merchant from "../components/merchant/Daftarmerchant.vue"; 

import Perkiraan from "../components/perkiraan/Daftarperkiraan.vue";
import PerkiraanManage from "../components/perkiraan/Manageperkiraan.vue";

import Pengguna from "../components/pengguna/Daftarpengguna.vue";
import PenggunaManage from "../components/pengguna/Managepengguna.vue";

import PesananRental from "../components/pesananrental/Daftarpesananrental.vue";

import Hutang from "../components/hutang/Daftarhutang.vue";
import HutangManage from "../components/hutang/Managehutang.vue";
import Piutang from "../components/piutang/Daftarpiutang.vue";
import PiutangManage from "../components/piutang/Managepiutang.vue";

import MataUang from "../components/matauang/Daftarmatauang.vue"; 
import MataUangManage from "../components/matauang/Managematauang.vue";



import PermintaanPembelian from "../components/permintaanb/Daftarpermintaanb.vue";
import PermintaanPembelianManage from "../components/permintaanb/Managepermintaanb.vue";

import PesananPembelian from "../components/pesananb/Daftarpesanan.vue";
import PesananPembelianManage from "../components/pesananb/Managepesanan.vue";
import PenerimaanBarang from "../components/penerimaan/Daftarpenerimaan.vue";
import PenerimaanBarangManage from "../components/penerimaan/Managepenerimaan.vue";

import FakturPembelian from "../components/beli/Daftarbeli.vue";
import FakturPembelianManage from "../components/beli/Managebeli.vue";

import ReturPembelian from "../components/rbeli/Daftarrbeli.vue";
import ReturPembelianManage from "../components/rbeli/Managerbeli.vue";
 
import BayarPembelian from "../components/bayarh/Daftarbayarh.vue";
import BayarPembelianManage from "../components/bayarh/Managebayarh.vue";

import PenawaranPenjualan from "../components/penawaran/Daftarpenawaran.vue";
import PenawaranPenjualanManage from "../components/penawaran/Managepenawaran.vue"; 

import PesananPenjualan from "../components/pesananj/Daftarpesananj.vue";
import PesananPenjualanManage from "../components/pesananj/Managepesananj.vue";

import PengirimanBarang from "../components/pengiriman/Daftarpengiriman.vue";
import PengirimanBarangManage from "../components/pengiriman/Managepengiriman.vue";

import FakturPenjualan from "../components/jual/Daftarjual.vue";
import FakturPenjualanManage from "../components/jual/Managejual.vue";

import ReturPenjualan from "../components/rjual/Daftarrjual.vue";
import ReturPenjualanManage from "../components/rjual/Managerjual.vue";

import BayarPenjualan from "../components/bayarp/Daftarbayarp.vue";
import BayarPenjualanManage from "../components/bayarp/Managebayarp.vue"; 

import PesananSafari from "../components/pesanansafari/Daftarpesanansafari.vue";

import PesananOffroad from "../components/pesananoffroad/Daftarpesananoffroad.vue";

import PesananOnroad from "../components/pesananonroad/Daftarpesananonroad.vue";

import PenjualanRental from "../components/penjualanrental/Daftarpenjualanrental.vue";
import PenjualanSafari from "../components/penjualansafari/Daftarpenjualansafari.vue";
import PenjualanOffroad from "../components/penjualanoffroad/Daftarpenjualanoffroad.vue";
import PenjualanOnroad from "../components/penjualanonroad/Daftarpenjualanonroad.vue";

import JurnalUmum from "../components/ju/Daftarju.vue";
import JurnalPenyesuaian from "../components/jurnalp/Daftarjurnalp.vue";
import JurnalPManage from "../components/jurnalp/Managejurnalp.vue";
import KasKeluar from "../components/kask/Daftarkask.vue";
import KasKManage from "../components/kask/Managekask.vue";
import KasKPrint from "../components/kask/Printkask.vue";
import KasMasuk from "../components/kasm/Daftarkasm.vue";
import KasMManage from "../components/kasm/Managekasm.vue";
import KasMPrint from "../components/kasm/Printkasm.vue";

import Penyusutan from "../components/penyusutan/Daftarpenyusutan.vue";
import PenyusutanManage from "../components/penyusutan/Managepenyusutan.vue";


import LaporanPsnRental from "../components/lappesananrental/Daftarlappesananrental.vue";
import LaporanPsnSafari from "../components/lappesanansafari/Daftarlappesanansafari.vue";
import LaporanPsnOffroad from "../components/lappesananoffroad/Daftarlappesananoffroad.vue";
import LaporanPsnOnroad from "../components/lappesananonroad/Daftarlappesananonroad.vue";

import LaporanPnjRental from "../components/lappenjualanrental/Daftarlappenjualanrental.vue";
import LaporanPnjSafari from "../components/lappenjualansafari/Daftarlappenjualansafari.vue";
import LaporanPnjOffroad from "../components/lappenjualanoffroad/Daftarlappenjualanoffroad.vue";
import LaporanPnjOnroad from "../components/lappenjualanonroad/Daftarlappenjualanonroad.vue";

import LaporanPermintaanBeli from "../components/lappermintaan/Daftarlappermintaan.vue";
import LaporanPesananBeli from "../components/lappesananb/Daftarlappesanan.vue";
import LaporanPenerimaanBarang from "../components/lappenerimaanb/Daftarlappenerimaan.vue";
import LaporanFakturPembelian from "../components/lapfakturbeli/Daftarlapfakturb.vue";
import LaporanReturPembelian from "../components/lapreturbeli/Daftarlapreturb.vue";
import LaporanBayarBeli from "../components/laphutang/Daftarlaphutang.vue";

import LaporanPenawaran from "../components/lappenawaran/Daftarlappenawaran.vue";
import LaporanPesananJual from "../components/lappesananj/Daftarlappesananj.vue";
import LaporanPengirimanBarang from "../components/lappengiriman/Daftarlappengiriman.vue";

import LaporanFakturPenjualan from "../components/lapfakturjual/Daftarlapfakturj.vue";
import LaporanReturPenjualan from "../components/lapreturjual/Daftarlapreturj.vue";

import LaporanKeuangan from "../components/lapkeuangan/Daftarlapkeuangan.vue"; 
import PrintNeraca from "../components/lapkeuangan/Printneraca.vue";
import PrintNeracaLajur from "../components/lapkeuangan/Printneracalajur.vue";
import PrintBB from "../components/lapkeuangan/Printbb.vue";
import PrintJurnal from "../components/lapkeuangan/Printjurnal.vue";
import PrintLaba from "../components/lapkeuangan/Printlr.vue";


import Setting from "../components/setting/Daftarsetting.vue";
import SettingPos from "../components/settingpos/Daftarsettingpos.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Login
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  }, 
  {
    path: "/bank",
    name: "Kas Dan Bank",
    component: Bank,
  },
  {
    path: "/bank/manage",
    name: "Kas Dan Bank Manage",
    component: BankManage,
  }, 
  
  {
    path: "/barang",
    name: "Barang",
    component: Barang,
  },
  {
    path: "/barang/manage",
    name: "Barang Manage",
    component: BarangManage,
  },  

  {
    path: "/gudang",
    name: "Gudang",
    component: Gudang,
  },
  {
    path: "/gudang/manage",
    name: "Gudang Manage",
    component: GudangManage,
  },  
  {
    path: "/suplier",
    name: "Suplier",
    component: Suplier,
  },
  {
    path: "/suplier/manage",
    name: "Suplier Manage",
    component: SuplierManage,
  }, 
  {
    path: "/salesman",
    name: "Salesman",
    component: Salesman,
  },
  {
    path: "/salesman/manage",
    name: "Salesman Manage",
    component: SalesmanManage,
  }, 
  {
    path: "/customer",
    name: "Customer",
    component: Customer,
  },
  {
    path: "/customer/manage",
    name: "Customer Manage",
    component: CustomerManage,
  }, 
  {
    path: "/cabang",
    name: "Cabang",
    component: Cabang,
  },
  {
    path: "/cabang/manage",
    name: "Cabang Manage",
    component: CabangManage,
  },  

  {
    path: "/pengguna",
    name: "Pengguna",
    component: Pengguna,
  },
  {
    path: "/pengguna/manage",
    name: "Pengguna Manage",
    component: PenggunaManage,
  }, 

  {
    path: "/tipeaktiva",
    name: "Tipe Aktiva",
    component: TipeAktiva,
  },
  {
    path: "/tipeaktiva/manage",
    name: "Tipe Aktiva Manage",
    component: TipeAktivaManage,
  }, 

  {
    path: "/kelompokaktiva",
    name: "Kelompok Aktiva",
    component: KelompokAktiva,
  },
  {
    path: "/kelompokaktiva/manage",
    name: "Kelompok Aktiva Manage",
    component: KelompokAktivaManage,
  }, 
  {
    path: "/matauang",
    name: "Mata Uang",
    component: MataUang,
  },
  {
    path: "/matauang/manage",
    name: "Mata Uang Manage",
    component: MataUangManage,
  }, 
  {
    path: "/hutang",
    name: "Hutang Awal",
    component: Hutang,
  },
  {
    path: "/hutang/manage",
    name: "Hutang Manage",
    component: HutangManage,
  }, 

  {
    path: "/piutang",
    name: "Piutang Awal",
    component: Piutang,
  },
  {
    path: "/Piutang/manage",
    name: "Piutang Manage",
    component: PiutangManage,
  }, 

  

  {
    path: "/cabang",
    name: "Merchant",
    component: Merchant,
  },
  {
    path: "/perkiraan",
    name: "Perkiraan",
    component: Perkiraan,
  },
  {
    path: "/perkiraan/manage",
    name: "Perkiraan Manage",
    component: PerkiraanManage,
  },
  {
    path: "/permintaanb",
    name: "Permintaan Pembelian",
    component: PermintaanPembelian,
  },
  {
    path: "/permintaanb/manage",
    name: "Permintaan Pembelian Manage",
    component: PermintaanPembelianManage,
  },
  {
    path: "/pesananb",
    name: "Pesanan Pembelian",
    component: PesananPembelian,
  },
  {
    path: "/pesananb/manage",
    name: "Pesanan Pembelian Manage",
    component: PesananPembelianManage,
  },
  {
    path: "/penerimaanb",
    name: "Penerimaan Barang",
    component: PenerimaanBarang,
  },
  {
    path: "/penerimaanb/manage",
    name: "Penerimaan Barang Manage",
    component: PenerimaanBarangManage,
  },
  {
    path: "/fakturbeli",
    name: "Faktur Pembelian",
    component: FakturPembelian,
  },
  {
    path: "/fakturbeli/manage",
    name: "Faktur Pembelian Manage",
    component: FakturPembelianManage,
  },
  {
    path: "/returbeli",
    name: "Retur Pembelian",
    component: ReturPembelian,
  },
  {
    path: "/returbeli/manage",
    name: "Retur Pembelian Manage",
    component: ReturPembelianManage,
  },

  {
    path: "/bayarbeli",
    name: "Pembayaran Pembelian",
    component: BayarPembelian,
  },
  {
    path: "/bayarbeli/manage",
    name: "Pembayaran Pembelian Manage",
    component: BayarPembelianManage,
  },
  {
    path: "/penawaranjual",
    name: "Penawaran Penjualan",
    component: PenawaranPenjualan,
  },
  {
    path: "/penawaranjual/manage",
    name: "Penawaran Penjualan Manage",
    component: PenawaranPenjualanManage,
  },
  {
    path: "/pesananjual",
    name: "Pesanan Penjualan",
    component: PesananPenjualan,
  },
  {
    path: "/pesananjual/manage",
    name: "Pesanan Penjualan Manage",
    component: PesananPenjualanManage,
  },

  {
    path: "/pengirimanbarang",
    name: "Pengiriman Barang",
    component: PengirimanBarang,
  },
  {
    path: "/pengirimanbarang/manage",
    name: "Pengiriman Barang Manage",
    component: PengirimanBarangManage,
  }, 
  {
    path: "/fakturjual",
    name: "Faktur Penjualan",
    component: FakturPenjualan,
  },
  {
    path: "/fakturjual/manage",
    name: "Faktur Penjualan Manage",
    component: FakturPenjualanManage,
  },

  {
    path: "/returjual",
    name: "Retur Penjualan",
    component: ReturPenjualan,
  },
  {
    path: "/returjual/manage",
    name: "Retur Penjualan Manage",
    component: ReturPenjualanManage,
  },
  {
    path: "/bayarjual",
    name: "Pembayaran Penjualan",
    component: BayarPenjualan,
  },
  {
    path: "/bayarjual/manage",
    name: "Pembayaran Penjualan Manage",
    component: BayarPenjualanManage,
  },
  




  {
    path: "/pesananrental",
    name: "Pesanan Rental",
    component: PesananRental,
  },
  {
    path: "/pesanansafari",
    name: "Pesanan Safari",
    component: PesananSafari,
  },
  {
    path: "/pesananoffroad",
    name: "Pesanan Offroad",
    component: PesananOffroad,
  },
  {
    path: "/pesananonroad",
    name: "Pesanan Onroad",
    component: PesananOnroad,
  },
  {
    path: "/penjualanrental",
    name: "Pendapatan Rental",
    component: PenjualanRental,
  },
  {
    path: "/penjualansafari",
    name: "Pendapatan Safari",
    component: PenjualanSafari,
  },
  {
    path: "/penjualanoffroad",
    name: "Pendapatan Offroad",
    component: PenjualanOffroad,
  },
  {
    path: "/penjualanonroad",
    name: "Pendapatan Onroad",
    component: PenjualanOnroad,
  },
  
  {
    path: "/jurnalumum",
    name: "Jurnal Umum",
    component: JurnalUmum,
  },
  {
    path: "/jurnalp",
    name: "Jurnal Penyesuaian",
    component: JurnalPenyesuaian,
  },
  {
    path: "/jurnalp/managejurnalp",
    name: "Jurnal Penyesuaian Manage",
    component: JurnalPManage,
  }, 
  {
    path: "/kask",
    name: "Kas Keluar",
    component: KasKeluar,
  },
  {
    path: "/kask/managekask",
    name: "Transaksi Kas Keluar Manage",
    component: KasKManage,
  }, 
  {
    path: "/kask/print",
    name: "PrintKasK",
    component: KasKPrint,
  }, 
  {
    path: "/kasm",
    name: "Kas Masuk",
    component: KasMasuk,
  },
  {
    path: "/kasm/print",
    name: "PrintKasM",
    component: KasMPrint,
  }, 
  {
    path: "/kasm/managekasm",
    name: "Transaksi Kas Masuk Manage",
    component: KasMManage,
  }, 

  {
    path: "/penyusutan",
    name: "Penyusutan Aktiva",
    component: Penyusutan,
  },
  {
    path: "/penyusutan/managepenyusutan",
    name: "Penyusutan Aktiva Manage",
    component: PenyusutanManage,
  }, 
  {
    path: "/laporanpsnrental",
    name: "L. Pesanan Rental",
    component: LaporanPsnRental,
  }, 
  {
    path: "/laporanpsnsafari",
    name: "L. Pesanan Safari",
    component: LaporanPsnSafari,
  }, 
  {
    path: "/laporanpsnoffroad",
    name: "L. Pesanan Offroad",
    component: LaporanPsnOffroad,
  }, 
  {
    path: "/laporanpsnonroad",
    name: "L. Pesanan Onroad",
    component: LaporanPsnOnroad,
  }, 
  {
    path: "/laporanpnjrental",
    name: "L. Pendapatan Rental",
    component: LaporanPnjRental,
  }, 
  {
    path: "/laporanpnjsafari",
    name: "L. Pendapatan Safari",
    component: LaporanPnjSafari,
  }, 
  {
    path: "/laporanpnjoffroad",
    name: "L. Pendapatan Offroad",
    component: LaporanPnjOffroad,
  }, 
  {
    path: "/laporanpnjonroad",
    name: "L. Pendapatan Onroad",
    component: LaporanPnjOnroad,
  },  
  {
    path: "/laporanpermintaan",
    name: "L. Permintaan Pembelian",
    component: LaporanPermintaanBeli,
  },
  {
    path: "/laporanpesananbeli",
    name: "L. Pesanan Pembelian",
    component: LaporanPesananBeli,
  },
  {
    path: "/laporanpenerimaanbarang",
    name: "L. Penerimaan Barang",
    component: LaporanPenerimaanBarang,
  },
  {
    path: "/laporanfakturpembelian",
    name: "L. Faktur Pembelian",
    component: LaporanFakturPembelian,
  },
  {
    path: "/laporanreturpembelian",
    name: "L. Retur Pembelian",
    component: LaporanReturPembelian,
  },
  {
    path: "/laporanhutang",
    name: "L. Hutang",
    component: LaporanBayarBeli,
  },
  {
    path: "/laporanpenawaran",
    name: "L. Penawaran",
    component: LaporanPenawaran,
  },
  {
    path: "/laporanpesananjual",
    name: "L. Pesanan Penjualan",
    component: LaporanPesananJual,
  },
  {
    path: "/laporanpengiriman",
    name: "L. Pengiriman Barang",
    component: LaporanPengirimanBarang,
  },
  {
    path: "/laporanfakturjual",
    name: "L. Faktur Penjualan",
    component: LaporanFakturPenjualan,
  },
  {
    path: "/laporanreturjual",
    name: "L. Retur Penjualan",
    component: LaporanReturPenjualan,
  },

  {
    path: "/laporankeuangan",
    name: "L. Keuangan",
    component: LaporanKeuangan,
  },   
  {
    path: "/printneraca",
    name: "Neraca",
    component: PrintNeraca,
  },
  {
    path: "/printbukubesar",
    name: "Buku Besar",
    component: PrintBB,
  },
  {
    path: "/printjurnal",
    name: "L. Jurnal Umum",
    component: PrintJurnal,
  },
  {
    path: "/printlaba",
    name: "L. Laba Rugi",
    component: PrintLaba,
  },
  {
    path: "/printneracalajur",
    name: "L. Neraca Lajur",
    component: PrintNeracaLajur,
  },

  {
    path: "/setting",
    name: "Setting",
    component: Setting,
  }, 
  {
    path: "/settingpos",
    name: "Setting Akun",
    component: SettingPos,
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
