<template>
  <v-app id="inspire">
    <Vmenu />
    <v-card flat>
      <v-card-title>
        {{ menu }} 
      </v-card-title>
      <v-card flat>
        <v-row align="center" class="ml-3 mt-0"> 
          <v-btn
            tile
            small
            color="primary"
            class="mx-1"
            @click="manage" 
          > 
            <v-avatar size="25" tile>
              <v-img :src="iconadd"></v-img>
            </v-avatar>
            Tambah
          </v-btn>
          <v-btn
            tile
            small
            color="success"
            class="ml-1" 
            @click="renewdata" 
          >
            <v-avatar size="25" tile>
              <v-img :src="iconrefresh"></v-img>
            </v-avatar>
            Refresh
          </v-btn> 
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            no-gutters
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                tile
                small
                class="ml-1"
                color="error" 
                width="100px"               
                v-bind="attrs"
                v-on="on"                
              > 
                <v-avatar size="25" tile>
                  <v-img :src="iconfilter"></v-img>
                </v-avatar>
                Filter
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>    
                    <v-col cols="12" sm="6">
                      <v-menu
                        ref="menudari"
                        v-model="menudari"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dari"
                            label="Periode"
                            hint="YYYY-MM"
                            persistent-hint 
                            v-bind="attrs" 
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dari"
                          type="month"
                          no-title
                          @input="menudari = false"
                        ></v-date-picker>
                      </v-menu> 
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srnobukti"
                        label="No Bukti"
                        hint="Search No Bukti"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srcustomer"
                        label="Nama Customer"
                        hint="Search Customer"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="srterm"
                        type="number"
                        label="term"
                        hint="Termin"
                        persistent-hint
                      ></v-text-field>
                    </v-col> 
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="getdata"
                >
                  Search
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> 
        </v-row>  
      </v-card>
      <div class="col">
        <v-card flat>    
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    No
                  </th>
                  <th class="text-left">
                    Tgl
                  </th>
                  <th class="text-left">
                    Nofaktur
                  </th> 
                  <th class="text-left">
                    Pelanggan
                  </th>
                  <th class="text-left">
                    Term
                  </th>
                  <th class="text-left">
                    Piutang Awal
                  </th>
                  <th class="text-left">
                    Piutang
                  </th>
                  <th class="text-left">
                    Bayar
                  </th>
                  <th class="text-left">
                    Retur
                  </th>
                  <th class="text-left">
                    Sisa
                  </th>
                  <th class="text-left">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in datatabel"
                  :key="item.no"
                >
                  <td>{{ item.no }}</td>
                  <td>{{ item.tgl }}</td>
                  <td>{{ item.nofaktur }}</td> 
                  <td>{{ item.customer }}</td>
                  <td>{{ item.term }}</td>
                  <td>{{ item.piutang_a }}</td> 
                  <td>{{ item.piutang }}</td> 
                  <td>{{ item.bayar }}</td> 
                  <td>{{ item.retur }}</td> 
                  <td>{{ item.sisa }}</td> 
                  <td>
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </td> 
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
    </v-card> 
     
  </v-app>
</template>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from 'axios'; 

export default {
  components: {
    Vmenu
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      periode:Constant.PERIODE,
      search: "", 
      datatabel: [], 
      loading: true,  
      iconedit: Constant.iconedit,
      iconhapus: Constant.icondelete,
      iconadd: Constant.iconadd,
      iconrefresh: Constant.iconrefresh,
      iconfilter: Constant.iconfilter,
      dialog:false,
      selectedcabang: { nomor: "0", desk: "-Pilih Cabang-" }, 
      datacabang: [{nomor: "0", desk: "-Pilih Cabang-"}],
      menudari: false,
      dari: new Date().toISOString().substr(0, 7), 
      srnobukti:"",
      srterm:"",
      srcustomer:"", 
    };
  },
  methods: {
    async getdata() {  
      this.loading = true; 
      const datakirim = {
        idlog: Constant.IDLOG_APP, 
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        branch: Constant.BRANCH_APP, 
        periode: this.dari.replace("-",""),  
        srnobukti: this.srnobukti,
        srterm: this.srterm,
        srcustomer: this.srcustomer
      };  
     
      await axios
        .post(Constant.API_ADDR + "piutang/daftarpiutang", datakirim)
        .then(response => {
          if (response.data.status === 102) 
          {
            this.datatabel = response.data.data; 
          }  
          else
          {
           if(response.data.status == 100)
           {
             alert(response.data.message)
             this.Constant.USER_APP = '';
             this.Constant.TOKEN_APP = ''; 
           } 
          }
        })
        .catch(errros => {
          this.dialog = false;
          console.log("error");
        }); 
      this.dialog = false;
      this.loading = false;  
    },
    editItem(item) { 
      this.$router.push({ name: "Piutang Manage", 
        params: { Id: item.id, Jenis:'editdata', 
        idcabang: item.idcabang, 
        nobukti:item.nofaktur, tanggal: item.tgl, kodecustomer:item.idcustomer, 
        kodesalesman:item.idsalesman,
        termin:item.term, piutangawal:item.piutang_a } 
        });
    },
    deleteItem(item) {
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP,  
        idpiutang: item.id,
        idcabang: item.idcabang
      };  
      axios
        .post(Constant.API_ADDR + "piutang/hapuspiutang", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            alert('sukses menghapus data')
            this.renewdata()  
          }
          else
          {
            alert(response.data.message)
          }
        })
        .catch((errros) => {
          console.log("error");
        });  
      this.loading = false;  
    },    
    renewdata() 
    {
      this.srterm = 0;
      this.srnobukti = ""
      this.srcustomer = ""
      this.getdata();
    }, 
    manage()
    {  
      if (Constant.USER_APP != "" && Constant.TOKEN_APP != "") 
      { 
        this.$router.push({ name: "Piutang Manage", 
        params: { Id: '-1', Jenis:'tambahdata', 
        idcabang: 0, 
        nobukti:'', tanggal: new Date().toISOString().substr(0, 10), kodecustomer:'0', 
        kodesalesman:'0', termin:30,
        piutangawal:0  } });
      } 
    }, 
  },
  created() 
  {
    Constant.IMENU = 'Piutang Awal';
    this.getdata();
  },
  mounted() {},
};
</script>
