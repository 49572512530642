<template>
  <v-app id="inspire">
    <Vmenu /> 
    <div class="forme">
      <v-container>
        <div>
          <div class="mt-3 title font-weight-bold">
            {{ menus }}
          </div> 
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          > 
            <v-row align="center" no-gutters>   
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="nama" 
                  label="Nama"
                  required
                  class="pa-1"
                  ref="nama"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="kode" 
                  label="Kode"
                  @focus="getnewcode"
                  required
                  class="pa-1"
                  ref="kode"
                ></v-text-field>
              </v-col>  
              <v-col cols="12" sm="4"></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="alamat" 
                  label="Alamat"
                  required
                  class="pa-1"
                  ref="alamat"
                ></v-text-field>
              </v-col> 
              
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="kota" 
                  label="Kota"
                  required
                  class="pa-1"
                  ref="kota"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="4"></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="telp" 
                  label="Telp"
                  required
                  class="pa-1"
                  ref="telp"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="4"></v-col>
              <v-col cols="12" sm="6" class="pa-1">
                <v-btn color="primary" 
                  :loading="loading"
                  :disabled="loading"
                  @click="simpandata">
                  Simpan
                </v-btn> 
                <v-btn color="error" class="ml-2" @click="batal">
                  batal
                </v-btn>  
              </v-col>  
            </v-row>
          </v-form>
        </div> 
      </v-container>
    </div>
     
  </v-app>
</template>

<style scoped>
  .forme {
    width: 100%;
    height: 100%; 
    position: relative; 
    background-color: grey lighten-5;
  } 
</style>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from "axios";

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      loading:false,
      valid: true,
      vid:-1,
      vjenis:'tambahdata',
      menus:'',       
      kode:'',
      nama:'',
      alamat:'',
      kota:'',
      cp:'',      
      telp:"",
    };
  },
  methods: { 
    getnewcode()
    {
      this.loading = true;  
      if(this.vjenis == 'tambahdata')
      { 
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP, 
          search: this.nama
        };  
        axios
          .post(Constant.API_ADDR + "penjual/getnewcode", datakirim)
          .then((response) => {
            if(response.data.status == 102)
            {  
              this.kode = response.data.data  
            }
            else
            { 
              alert(response.data.message)         
              this.$refs.nama.focus()
            }   
          })
          .catch((errros) => {
            console.log("error");
          }); 
      }
      this.loading = false;  
    },
    simpandata()
    {
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        jenis: this.vjenis,
        id: this.vid,
       
        kode: this.kode,
        nama: this.nama,
        alamat: this.alamat,
        kota: this.kota,
        cp: this.cp,
        telp: this.telp,
         
      };  
      axios
        .post(Constant.API_ADDR + "penjual/simpandata", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.vid            = -1
            this.vjenis         = 'tambahdata'
            this.loading        = false; 
            this.bersih()
          }
          else
          {
            alert(response.data.message)
            this.loading = false; 
          } 
        })
        .catch((errros) => {
          this.loading = false; 
          console.log("error");
        });   
    },
    batal(){ 
      this.$router.push({ name: "Salesman" }); 
    }, 
    async getmaster(){
      this.loading = true; 
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP 
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.dataakun = response.data.dataakun4
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false; 
    },
    bersih(){
      this.kode = ''
      this.nama = ''
      this.alamat = ''
      this.kota = ''
      this.cp = ''
      this.telp = ''
    }
  },
  created() {
    Constant.IMENU = "Manage Salesman";
    // this.getmaster(); 
    this.menus = Constant.IMENU;
    this.vid = this.$route.params.Id
    this.vjenis = this.$route.params.Jenis     
    
    this.nama = this.$route.params.nama
    this.kode = this.$route.params.kode
    this.alamat = this.$route.params.alamat
    this.kota = this.$route.params.kota
    this.cp = this.$route.params.cp
    this.telp = this.$route.params.telp 
  },
  mounted() {},
  watch: {},
};
</script>
