<template>
  <v-app id="inspire">
    <v-main> 
      <v-container class="fill-height" fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar color="teal" dark flat>
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text> 
                <v-form>
                  <div class="d-flex">
                    <v-avatar class="mt-4 mr-2 ml-2" size="35" tile>
                      <v-img :src="iconuser"></v-img>
                    </v-avatar>
                    <v-card class="mr-2" flat width="100%">
                      <v-text-field
                        v-model="nohp"
                        label="User Name"
                        name="login" 
                        type="text"
                        :maxlength="maxnohp"
                        autocomplete="off"
                        :rules="[rules.required]"   
                      ></v-text-field> 
                    </v-card>                   
                  </div>

                  <div class="d-flex">
                    <v-avatar class="mt-4 mr-2 ml-2" size="35" tile>
                      <v-img :src="iconkunci"></v-img>
                    </v-avatar>
                    <v-card class="mr-2" flat width="100%">
                      <v-text-field
                        v-model="pin"
                        id="password"
                        label="Password"
                        name="password" 
                        type="password"
                        :maxlength="maxpin"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-card>                   
                  </div> 
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                  class="ma-2 white--text" 
                  dark 
                  color="teal" 
                  :disabled="btnlogin"
                  @click="login"
                  :loading="loading"

                >Login</v-btn
                >
              </v-card-actions>
              <v-card-text class="red--text">{{ psn }}</v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>      
    </v-main>
  </v-app>
</template>

<script>
import Constant from "@/components/Constant.vue";
import axios from "axios";   


export default {
  name: "Login",
  props: {
    source: String,
  },
  data() {
    return {  
      // nohp: "085640290712", // superadmin
      // pin: "1234Abcd", // superadmin
      nohp: "merchant", // merchant
      pin: "m", // merchant 
      // nohp: "",
      // pin: "",
      maxnohp: 20,
      maxpin: 50,
      rules: {
        required: (value) => !!value || "Required.",
        minpin: (v) => v.length <= 6 || "Min 6 characters",
      },
      psn: "",
      btnlogin: false,
      loading:false,
      iconuser: Constant.API_ADDR + "assets/images/user.png",
      iconkunci: Constant.API_ADDR + "assets/images/kunci.png", 
      drawer: false,
      group: null,  
      
    };
  },
  methods: {
    async login() {  
      this.loading = true;
      const datakirim = {
        telp: this.nohp,
        pass: this.pin,
      };
      await axios
        .post(Constant.API_ADDR + "login/login", datakirim)
        .then((response) => {
          if (response.data.status === 102 || response.data.status === "102") {
            this.loading = false   
            localStorage.clear()        
            Constant.USER_NAME = this.nohp;
            Constant.TOKEN_APP = response.data.token;
            Constant.USER_APP = response.data.userkey;
            Constant.IDLOG_APP = response.data.idlog;
            Constant.BRANCH_APP = response.data.branch;
            Constant.APP_NAME = response.data.developed;
            Constant.PERIODE = response.data.periode;
            Constant.ITEMMENU = response.data.itemmenu;
            Constant.STARTDATE = response.data.startdate; 
            localStorage.setItem("API_ADDR", Constant.API_ADDR)
            localStorage.setItem("TOKEN_APP", response.data.token)
            localStorage.setItem("USER_APP", response.data.userkey)
            localStorage.setItem("IDLOG_APP", response.data.idlog)
            localStorage.setItem("BRANCH_APP", response.data.branchid)
            localStorage.setItem("APP_NAME", response.data.developed)
            // localStorage.setItem("ITEMMENU", JSON.stringify(Constant.ITEMMENU)) 
            localStorage.setItem("PERIODE", response.data.startdate)  
            this.$router.push({ name: "Dashboard" });
          } else {
            this.psn = response.data.message;
            Constant.TOKEN_APP = "";
            Constant.USER_APP = "";
            Constant.IDLOG_APP = "";
            Constant.BRANCH_APP = "";
            localStorage.clear() 
          }
        })
        .catch((errros) => {
          console.log("error");
          Constant.TOKEN_APP = "";
          Constant.USER_APP = "";
          Constant.IDLOG_APP = "";
          Constant.BRANCH_APP = "";
          localStorage.clear() 
        });
      this.loading = false
    },  
  },
  watch: {
      group () {
        this.drawer = false
      },
    }, 
  created() { }, 
  mounted() { },
};
</script>
