<template>
  <v-app id="inspire">
    <Vmenu /> 
    <div class="forme">
      <v-container>
        <div>
          <div class="mt-3 title font-weight-bold">
            {{ menus }}
            <v-btn icon class="ml-n2 pa-0">
              <v-avatar size="30" @click="getmaster" tile>
                <v-img :src="icondownload"></v-img>
              </v-avatar>
            </v-btn> 
          </div> 
          <div class="text-center">
            
          </div> 
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          > 
            <v-row align="center" no-gutters>  
              <v-col cols="12"  sm="3" >
                <v-autocomplete 
                  v-model="selectedcabang.nomor"
                  :items="datacabang"
                  item-text="desk"
                  item-value="nomor"  
                  clearable 
                  label="Data Cabang"  
                  ref="refcabang"
                  class="pa-1" 
                ></v-autocomplete>
              </v-col> 
              <v-col cols="12" sm="9"></v-col>
              <v-col cols="12" sm="3">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tgl"
                      label="Tanggal"  
                      persistent-hint 
                      v-bind="attrs" 
                      v-on="on"
                      class="pa-1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tgl"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu> 
              </v-col>
               
              <v-col cols="12" sm="3"  >
                <v-text-field
                  v-model="nobukti"
                  :rules="NoRules"
                  label="Nobukti" 
                  required 
                  @focus="getnewcode"
                  class="pa-1"
                ></v-text-field>
              </v-col>  
              <v-col cols="6"></v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="desk" 
                  label="Deskripsi"  
                  class="pa-1"
                ></v-text-field>
              </v-col> 
               
               
              <v-col cols="12" sm="6"> </v-col>

              <v-col cols="12" sm="3">
                <v-btn 
                  block
                  tile
                  color="primary"  
                  @click="dialog = true"
                  class="pa-1"
                >
                  Transaksi Detail 
                </v-btn> 
              </v-col> 

               

              <!-- tabel -->
              <v-col cols="12" class="pa-1">
                <v-card flat>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Kode Barang
                          </th>
                          <th class="text-left">
                            Nama
                          </th>
                          <th class="text-left">
                            Kts
                          </th> 
                          <th class="text-left">
                            Satuan
                          </th>  
                          <th class="text-left">
                            Aksi
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in datatabel"
                          :key="item.id"
                        >
                          <td>{{ item.kode }}</td>
                          <td>{{ item.nama }}</td>
                          <td>{{ item.qty }}</td> 
                          <td>{{ item.satuankecil }}</td>  
                          <td> 
                            <v-btn x-small icon max-width="15px" class="ma-1">
                              <v-avatar size="15" @click="editItemd(item)" tile>
                                <v-img :src="iconedit"></v-img>
                              </v-avatar>
                            </v-btn>
                            <v-btn x-small icon max-width="15px" class="ma-1">
                              <v-avatar  size="15" @click="deleteItemd(item)" tile>
                                <v-img :src="iconhapus"></v-img>
                              </v-avatar>
                            </v-btn>   
                          </td> 
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>  
              </v-col>  

              <v-col cols="12" sm="12" class="pa-1">
                <v-btn color="primary" small
                  :loading="loading"
                  :disabled="loading"
                  @click="simpandata">
                  Simpan
                </v-btn> 
                <v-btn color="error" small class="ml-2" @click="batal">
                  batal
                </v-btn> 
                <v-btn color="success" small class="ml-2" @click="tambahdata">
                  Tambah
                </v-btn>  
                <v-btn color="warning" small 
                  :loading="loadcetak"
                  :disabled="loadcetak"
                  class="ml-2" @click="cetak">
                  cetak
                </v-btn>
                
              </v-col>  
            </v-row>
          </v-form>

          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
          > 
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete 
                        v-model="selectedbarang.nomor"
                        :items="databarang"
                        item-text="desk"
                        item-value="nomor"   
                        :rules="[v => !!v || 'Item is required']"
                        label="Data Barang"
                        required  
                      ></v-autocomplete> 
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        v-model="kts"
                        label="Kts" 
                      ></v-text-field>
                    </v-col>  
                    
                    
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="simpandata"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
           
        </div> 
      </v-container>
    </div>
     
  </v-app>
</template>

<style scoped>
  .forme {
    width: 100%;
    height: 100%; 
    position: relative; 
    background-color: grey lighten-5;
  } 
</style>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from "axios";

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      dialog:false,
      dialogfaktur:false,
      valid: true,
      menu1:false,
      loading:false,
      loadcetak:false, 
      icondownload: Constant.icondownload,
      iconedit: Constant.iconedit,
      iconhapus: Constant.icondelete, 
      vidh:-1,
      vidd:-1,
      vjenis:'tambahdata',
      menus:'',   
      selectedcabang: { nomor: "0", desk: "-Pilih Cabang-" }, 
      datacabang: [{nomor: "0", desk: "-Pilih Cabang-"}],
      tgl: new Date().toISOString().substr(0, 10), 
      nobukti:'', 
      desk:'',  
      kts:0, 
      selectedbarang: { nomor: "0", desk: "-Pilih Barang-" }, 
      databarang: [{nomor: "0", desk: "-Pilih Barang-"}],  
      fakturdetail:[],
      faktur:[],
      datatabel:[],
      NoRules: [
        v => !!v || 'Field is required', 
      ],    
    };
  },
  methods: { 
    getnewcode()
    {
      this.loading = true;  
      if(this.vjenis == 'tambahdata')
      { 
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP, 
          branch: Constant.BRANCH_APP, 
          idcabang: this.selectedcabang.nomor,
          search: this.tgl
        };  
        axios
          .post(Constant.API_ADDR + "permintaan/getnewcode", datakirim)
          .then((response) => {  
            if(response.data.status == 102)
            {
              this.nobukti = response.data.data   
            }
            else
            { 
              this.$refs.refcabang.focus();
              alert(response.data.message)    
            }
            
          })
          .catch((errros) => {
            console.log("error");
          }); 
      }
      this.loading = false;  
    },
    simpandata()
    {
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP, 
        jenis: this.vjenis,
        idh: this.vidh,
        tanggal: this.tgl,
        nobukti: this.nobukti,
        idcabang: this.selectedcabang.nomor,
        desk: this.desk,
        idd:this.vidd,
        idbarang: this.selectedbarang.nomor, 
        qty: this.kts,
      };  
      axios
        .post(Constant.API_ADDR + "permintaan/simpandata", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.selectedbarang.nomor = "0"
            this.vjenis = 'editdata'
            this.vidh = response.data.datah.id
            this.datatabel = response.data.datad 
            this.vidd = -1
            this.kts = 0
            this.loading = false; 
            alert(response.data.message)
          }
          else
          {
            alert(response.data.message)
            this.loading = false; 
          }

        })
        .catch((errros) => {
          console.log("error");
          this.loading = false; 
        });  
        
      
    },
    batal(){ 
      this.$router.push({ name: "Permintaan Pembelian" }); 
    },
    async getcabang(){
      this.loading = true; 
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP 
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.datacabang = response.data.datacabang 
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false; 
    },
    async getmaster(){
      this.loading = true; 
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.databarang = response.data.databarang 
            this.datacabang = response.data.datacabang2 
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false; 
    }, 
    tambahdetail(){
      this.selectedbarang.nomor = '0'
      this.vidd = -1 
      this.kts = 0 
      this.dialog = true
    },
    tambahdata(){
      this.vidh = -1
      this.vjenis = 'tambahdata' 
      this.nobukti = ''
      this.tgl = new Date().toISOString().substr(0, 10) 
      this.desk = "" 
      this.datatabel = []
      this.selectedcabang.nomor = "0"
    },
    editItemd(item){ 
      this.selectedbarang.nomor = item.kodebarang
      this.vidd = item.id
      this.kts = item.qty 
      this.dialog = true
    },
    deleteItemd(item){
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP,  
        idfakturh: item.idh, 
        idfakturd: item.id,
        idcabang: this.selectedcabang.nomor
      };  
      axios
        .post(Constant.API_ADDR + "permintaan/hapusdatad", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            alert('sukses menghapus data')
            this.datatabel = response.data.datad 
            this.loading = false            
          }
          else
          {
            alert(response.data.message)
            this.loading = false;  
          }
        })
        .catch((errros) => {
          console.log("error");
          this.loading = false;  
        });   
    },
    cetak(){
      this.loadcetak = true;  
      if(this.vidh > 0) 
      {
        // kirim data
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP, 
          branch: Constant.BRANCH_APP,  
          idpermintaan: this.vidh,  
        };  
        axios
        .post(Constant.API_ADDR + "permintaan/request", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.loadcetak = false  
            window.open(Constant.API_ADDR + "printpreviewfaktur/cetak/" + response.data.data) 
          }
          else
          {
            alert(response.data.message)
            this.loadcetak = false;  
          }
        })
        .catch((errros) => {
          console.log("error");
          this.loadcetak = false;  
        });   
      } 
      this.loadcetak = false;  
    },
     
  },
  created() {
    Constant.IMENU = "Manage Permintaan Pembelian"; 
    this.menus = Constant.IMENU;
    this.getmaster(); 
    this.vidh = this.$route.params.Id
    this.vjenis = this.$route.params.Jenis 
    this.selectedcabang.nomor = this.$route.params.idcabang
    this.tgl = this.$route.params.tgl
    this.nobukti = this.$route.params.nobukti
    this.desk = this.$route.params.desk
    this.datatabel = this.$route.params.datatabel 
    this.selectedbarang.nomor = "0" 
  },
  mounted() {},
  watch: {},
  computed: {}
};
</script>
