<template>
  <v-app id="inspire">
    <Vmenu /> 
    <div class="forme">
      <v-container>
        <div>
          <div class="mt-3 sub-title font-weight-bold">
            {{ menus }}
            <v-btn icon class="ml-n2 pa-0">
              <v-avatar size="30" @click="getmaster" tile>
                <v-img :src="icondownload"></v-img>
              </v-avatar>
            </v-btn>  
          </div> 
           
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          > 
            <v-row align="center" no-gutters>  
              <v-col cols="12"  sm="3" >
                <v-autocomplete 
                  v-model="selectedcabang.nomor"
                  :items="datacabang"
                  item-text="desk"
                  item-value="nomor"  
                  clearable 
                  label="Data Cabang"  
                  ref="refcabang"
                  class="pa-1"
                  @input="getmaster()"
                ></v-autocomplete>
              </v-col> 
              <v-col cols="12" sm="9"></v-col>
              <v-col cols="12" sm="3">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tgl"
                      label="Tanggal"  
                      persistent-hint 
                      v-bind="attrs" 
                      v-on="on"
                      class="pa-1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tgl"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu> 
              </v-col>
               
              <v-col cols="12" sm="3"  >
                <v-text-field
                  v-model="nobukti"
                  :rules="NoRules"
                  label="Nobukti" 
                  required 
                  @focus="getnewcode"
                  class="pa-1"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="1" >
                <v-text-field
                  v-model="term" 
                  label="Term"  
                  class="pa-1"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="2"> 
                <v-checkbox
                  v-model="pjk"
                  label="Faktur Termasuk Pajak"
                ></v-checkbox>
              </v-col> 
              <v-col cols="12" sm="3"> </v-col>
              <v-col cols="12" sm="3" >
                <v-autocomplete 
                  v-model="selectedsalesman.nomor"
                  :items="datasalesman"
                  item-text="desk"
                  item-value="nomor"  
                  clearable 
                  label="Data Salesman"  
                  ref="refsalesman"
                  class="pa-1"
                ></v-autocomplete>
              </v-col>  
              <v-col cols="12"  sm="3" >
                <v-autocomplete 
                  v-model="selectedcustomer.nomor"
                  :items="datacustomer"
                  item-text="desk"
                  item-value="nomor"  
                  clearable 
                  label="Data Customer"  
                  ref="refsuplier"
                  class="pa-1"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                  v-model="itemfaktur"
                  :items="items"
                  label="Pilih Faktur"
                  clearable
                  class="pa-1"
                  @input="pilihfaktur"
                ></v-select>
              </v-col>    
              <v-col cols="12" sm="3"> </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="discount" 
                  label="Rp Diskon"  
                  class="pa-1"
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="ppn" 
                  label="PPN"  
                  class="pa-1"
                ></v-text-field>
              </v-col>  
              <v-col cols="12" sm="6"> </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="desk" 
                  label="Deskripsi"  
                  class="pa-1"
                ></v-text-field>
              </v-col>  
              <v-col cols="12" sm="6"> </v-col>

              <v-col cols="12" sm="3">
                <v-btn 
                  block
                  tile
                  color="primary"  
                  @click="dialog = true"
                  class="pa-1"
                >
                  Transaksi Detail 
                </v-btn> 
              </v-col> 

              <v-col cols="12" sm="3"> 
                <p class="text-h6 font-weight-bold pa-1">
                  Rp PPN : {{ formatPrice(rpppn) }}
                </p> 
              </v-col>
              <v-col cols="12" sm="3"> 
                <p class="text-h6 font-weight-bold pa-1">
                  Sub Total : {{ formatPrice(jumlahbrutto) }}
                </p> 
              </v-col>
              <v-col cols="12" sm="3"> 
                <p class="text-h6 font-weight-bold pa-1">
                  Total : {{ formatPrice(netto) }}
                </p> 
              </v-col> 

              <!-- tabel -->
              <v-col cols="12" class="pa-1">
                <v-card flat>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Kode Barang
                          </th>
                          <th class="text-left">
                            Nama
                          </th>
                          <th class="text-left">
                            Kts
                          </th>
                          <th class="text-left">
                            Harga
                          </th>
                          <th class="text-left">
                            Satuan
                          </th>
                          <th class="text-left">
                            Diskon
                          </th>
                          <th class="text-left">
                            Rp Diskon
                          </th>
                          <th class="text-left">
                            Harga Jual Netto
                          </th>
                          <th class="text-left">
                            Jumlah Netto
                          </th> 
                          <th class="text-left">
                            Aksi
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in datatabel"
                          :key="item.id"
                        >
                          <td>{{ item.kode }}</td>
                          <td>{{ item.nama }}</td>
                          <td>{{ item.qty }}</td>
                          <td>{{ formatPrice(item.hargad) }}</td>
                          <td>{{ item.satuankecil }}</td>
                          <td>{{ item.diskon }}</td>
                          <td>{{ formatPrice(item.rpdiscount) }}</td>  
                          <td>{{ formatPrice(item.hargajualnetto) }}</td>
                          <td>{{ formatPrice(item.jumlahjualnetto) }}</td> 
                          <td>
                            <v-btn x-small icon max-width="15px" class="ma-1">
                              <v-avatar size="15" @click="editItemd(item)" tile>
                                <v-img :src="iconedit"></v-img>
                              </v-avatar>
                            </v-btn>
                            <v-btn x-small icon max-width="15px" class="ma-1">
                              <v-avatar  size="15" @click="deleteItemd(item)" tile>
                                <v-img :src="iconhapus"></v-img>
                              </v-avatar>
                            </v-btn>   
                          </td> 
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>  
              </v-col>  

              <v-col cols="12" sm="12" class="pa-1">
                <v-btn color="primary" small
                  :loading="loading"
                  :disabled="loading"
                  @click="simpandata">
                  Simpan
                </v-btn> 
                <v-btn color="error" small class="ml-2" @click="batal">
                  batal
                </v-btn> 
                <v-btn color="success" small class="ml-2" @click="tambahdata">
                  Tambah
                </v-btn>  
                <v-btn color="warning" small 
                  :loading="loadcetak"
                  :disabled="loadcetak"
                  class="ml-2" @click="cetak">
                  cetak
                </v-btn>
                
              </v-col>  
            </v-row>
          </v-form>

          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
          > 
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete 
                        v-model="selectedbarang.nomor"
                        :items="databarang"
                        item-text="desk"
                        item-value="nomor"   
                        :rules="[v => !!v || 'Item is required']"
                        label="Data Barang"
                        required  
                        @input="gethargabarang(selectedbarang.nomor)"
                      ></v-autocomplete> 
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        v-model="kts"
                        label="Kts" 
                      ></v-text-field>
                    </v-col>  
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        v-model="harga"
                        label="Harga" 
                      ></v-text-field>
                    </v-col>  
                    <v-col cols="12">
                      <v-text-field 
                        v-model="diskond"
                        label="Diskon" 
                      ></v-text-field>
                    </v-col>  
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="simpandata"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- pilih faktur -->
          <v-dialog
            v-model="dialogfaktur" 
            max-width="600px"
          > 
            <v-card>
              <v-card-title>
                {{ itemfaktur }}
              </v-card-title> 
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Tgl
                        </th>
                        <th class="text-left">
                          Nobukti
                        </th> 
                        <th class="text-left">
                          Aksi
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in fakturdetail"
                        :key="item.id"
                      >
                        <td>{{ item.tgl }}</td>
                        <td>{{ item.nobukti }}</td>
                        <td>
                          <v-checkbox 
                            v-model="faktur" 
                            :label="item.nobukti"
                            :value="item.id"
                          ></v-checkbox>
                        </td> 
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>  
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogfaktur = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="simpandatafaktur"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> 
        </div> 
      </v-container>
    </div>
     
  </v-app>
</template>

<style scoped>
  .forme {
    width: 100%;
    height: 100%; 
    position: relative; 
    background-color: grey lighten-5;
  } 
</style>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from "axios";

export default {
  components: {
    Vmenu,
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      dialog:false,
      dialogfaktur:false,
      valid: true,
      menu1:false,
      loading:false,
      loadcetak:false,
      vidh:-1,
      vidd:-1,
      vjenis:'tambahdata',
      menus:'',   
      selectedcabang: { nomor: "0", desk: "-Pilih Cabang-" }, 
      datacabang: [{nomor: "0", desk: "-Pilih Cabang-"}],
      tgl: new Date().toISOString().substr(0, 10), 
      nobukti:'', 
      term:0,
      pjk:false, 
      datasalesman: [{nomor: "0", desk: "-Pilih Salesman-"}],
      selectedsalesman: { nomor: "0", desk: "-Pilih Salesman-" }, 
      datacustomer: [{nomor: "0", desk: "-Pilih Customer-"}],
      selectedcustomer: { nomor: "0", desk: "-Pilih Customer-" }, 
      items:['Penawaran Penjualan', 'Pesanan Penjualan'],
      itemfaktur:'Pesanan Penjualan',
      desk:'',  
      discount:0,      
      ppn:0,
      rpppn:0,
      jumlahbrutto:0,
      netto:0,  
      kts:0, 
      harga:0, 
      diskond:0, 
      selectedbarang: { nomor: "0", desk: "-Pilih Barang-" }, 
      databarang: [{nomor: "0", desk: "-Pilih Barang-"}],  
      fakturdetail:[],
      faktur:[],
      datatabel:[],
      NoRules: [
        v => !!v || 'Field is required', 
      ],   
      iconedit: Constant.iconedit,
      iconhapus: Constant.icondelete,
      icondownload: Constant.icondownload, 
    };
  },
  methods: {  
    pilihfaktur()
    {  
      this.fakturdetail = []; 
      this.faktur = [];
      if(this.itemfaktur != 'Pesanan Penjualan' && this.itemfaktur != undefined)
      {     
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP, 
          branch: Constant.BRANCH_APP,
          idcabang: this.selectedcabang.nomor,
          tgl: this.tgl,
          idcustomer: this.selectedcustomer.nomor, 
          idsalesman: this.selectedsalesman.nomor
        };  
        axios
          .post(Constant.API_ADDR + "penawaran/getdata", datakirim)
          .then((response) => {   
            if(response.data.status == 102)
            {
              this.fakturdetail = response.data.data 
              this.dialogfaktur = true 
            }
            else
            {
              this.fakturdetail = [];
              this.faktur = [];
              alert(response.data.message)
            }
          })
          .catch((errros) => {
            console.log("error");
          }); 
      } 
    },
    getnewcode()
    {
      this.loading = true;  
      if(this.vjenis == 'tambahdata')
      { 
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP, 
          branch: Constant.BRANCH_APP, 
          idcabang: this.selectedcabang.nomor,
          search: this.tgl
        };  
        axios
          .post(Constant.API_ADDR + "pesananj/getnewcode", datakirim)
          .then((response) => {  
            if(response.data.status == 102)
            {
              this.nobukti = response.data.data   
            }
            else
            { 
              this.$refs.refcabang.focus();
              alert(response.data.message)    
            }
            
          })
          .catch((errros) => {
            console.log("error");
          }); 
      }
      this.loading = false;  
    },
    simpandata()
    {
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP, 
        jenis: this.vjenis,
        idh: this.vidh,
        tanggal: this.tgl,
        nobukti: this.nobukti,
        term: this.term,
        diskonh: this.discount,
        ppn: this.ppn,
        incpajak: this.pjk,
        kodesalesman: this.selectedsalesman.nomor,
        kodecustomer: this.selectedcustomer.nomor, 
        idcabang: this.selectedcabang.nomor,
        desk: this.desk,
        idd:this.vidd,
        idbarang: this.selectedbarang.nomor, 
        qty: this.kts,
        harga:this.harga,
        diskond:this.diskond
      };  
      axios
        .post(Constant.API_ADDR + "pesananj/simpandata", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.selectedbarang.nomor = "0"
            this.vjenis = 'editdata'
            this.vidh = response.data.datah.id
            this.datatabel = response.data.datad 
            this.vidd = -1
            this.kts = 0
            this.harga = 0
            this.diskond = 0
            this.rpppn = response.data.datah.rpppn
            this.jumlahbrutto = response.data.datah.jumlahbrutto
            this.netto = response.data.datah.netto
            this.loading = false; 
            alert(response.data.message)
          }
          else
          {
            alert(response.data.message)
            this.loading = false; 
          }

        })
        .catch((errros) => {
          console.log("error");
          this.loading = false; 
        });  
        
      
    },
    simpandatafaktur()
    {
      this.loading = true;   
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP, 
        jenis: this.vjenis,
        idh: this.vidh,
        tanggal: this.tgl,
        nobukti: this.nobukti,
        term: this.term,
        diskonh: this.discount,
        ppn: this.ppn,
        incpajak: this.pjk,
        kodesalesman: this.selectedsalesman.nomor,
        kodecustomer: this.selectedcustomer.nomor,  
        idcabang: this.selectedcabang.nomor,
        desk: this.desk,
        idd:this.vidd,
        faktur: this.itemfaktur,
        detailfaktur:this.faktur
      };  
      axios
        .post(Constant.API_ADDR + "pesananj/simpanfaktur", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.selectedbarang.nomor = "0"
            this.vjenis = 'editdata'
            this.vidh = response.data.datah.id
            this.datatabel = response.data.datad 
            this.vidd = -1
            this.kts = 0
            this.harga = 0
            this.diskond = 0
            this.rpppn = response.data.datah.rpppn
            this.jumlahbrutto = response.data.datah.jumlahbrutto
            this.netto = response.data.datah.netto
            this.loading = false; 
            this.fakturdetail = [];
            this.faktur = [];
          }
          else
          {
            alert(response.data.message)
            this.loading = false; 
          }

        })
        .catch((errros) => {
          console.log("error");
          this.loading = false; 
        });   
      this.fakturdetail = []
      this.faktur = [];
      this.dialogfaktur = false
    },
    batal(){ 
      this.$router.push({ name: "Pesanan Penjualan" }); 
    }, 
    async getmaster(){
      this.loading = true; 
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP, 
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.datacabang = response.data.datacabang2
            this.databarang = response.data.databarang
            this.datasalesman = response.data.datasalesman
            this.datacustomer = response.data.datacustomer
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false; 
    }, 
    tambahdetail(){
      this.selectedbarang.nomor = '0'
      this.vidd = -1 
      this.kts = 0
      this.harga = 0
      this.diskond = 0
      this.dialog = true
    },
    tambahdata(){
      this.vidh = -1
      this.vjenis = 'tambahdata' 
      this.nobukti = ''
      this.tgl = new Date().toISOString().substr(0, 10) 
      this.desk = "" 
      this.datatabel = []
      this.selectedcabang.nomor = "0"
      this.selectedsalesman.nomor = "0"
      this.selectedcustomer.nomor = "0"
      this.jumlahbrutto = 0
      this.netto = 0
      this.rpppn = 0
    },
    editItemd(item){ 
      this.selectedbarang.nomor = item.kodebarang
      this.vidd = item.id
      this.kts = item.qty
      this.harga = item.hargad
      this.diskond = item.diskon
      this.dialog = true
    },
    deleteItemd(item){
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP,  
        idfakturh: item.idh, 
        idfakturd: item.id,
        idcabang: this.selectedcabang.nomor
      };  
      axios
        .post(Constant.API_ADDR + "pesananj/hapusdatad", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            alert('sukses menghapus data')
            this.datatabel = response.data.datad 
            this.loading = false            
          }
          else
          {
            alert(response.data.message)
            this.loading = false;  
          }
        })
        .catch((errros) => {
          console.log("error");
          this.loading = false;  
        });   
    },
    cetak(){
      this.loadcetak = true;  
      if(this.vidh > 0) 
      {
        // kirim data
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP, 
          branch: Constant.BRANCH_APP,  
          idjual: this.vidh,  
        };  
        axios
        .post(Constant.API_ADDR + "pesananj/request", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.loadcetak = false  
            window.open(Constant.API_ADDR + "printpreviewfaktur/cetak/" + response.data.data) 
          }
          else
          {
            alert(response.data.message)
            this.loadcetak = false;  
          }
        })
        .catch((errros) => {
          console.log("error");
          this.loadcetak = false;  
        });   
      } 
      this.loadcetak = false;  
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    async gethargabarang(item)
    {
      this.loading = true;  
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        search: item
      };  
      await axios
        .post(Constant.API_ADDR + "barang/hargabarang", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.harga = response.data.hargajual
          }
          else
          {  
            this.harga = 0;
            alert(response.data.message)    
          } 
        })
        .catch((errros) => {
          console.log("error");
        }); 
      this.loading = false;   
    },  
     
  },
  created() {
    Constant.IMENU = "Manage Pesanan Penjualan";
    this.getmaster();  
    this.menus = Constant.IMENU;
    this.vidh = this.$route.params.Id
    this.vjenis = this.$route.params.Jenis 
    this.selectedcabang.nomor = this.$route.params.idcabang 
    this.tgl = this.$route.params.tgl
    this.nobukti = this.$route.params.nobukti
    this.term = this.$route.params.term
    if(this.$route.params.pjk == 1 || this.$route.params.pjk == "1")
    {
      this.pjk = true
    }
    else
    {
      this.pjk = false
    }  
    this.selectedsalesman.nomor = this.$route.params.idsalesman
    this.selectedcustomer.nomor = this.$route.params.idcustomer
    this.desk = this.$route.params.desk
    this.discount = this.$route.params.discount
    this.ppn = this.$route.params.ppn
    this.rpppn = this.$route.params.rpppn
    this.jumlahbrutto = this.$route.params.jumlahbrutto
    this.netto = this.$route.params.netto  
    this.datatabel = this.$route.params.datatabel 
    this.selectedbarang.nomor = "0" 
  },
  mounted() {},
  watch: {},
  computed: {}
};
</script>
