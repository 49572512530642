<template>
  <v-app id="inspire">
    <Vmenu />    
    <v-container fluid>
      <div class="ml-2 text-h6">{{ menu }}</div> 
      <!-- filter -->
      <v-row align="center" class="ml-2"> 
        <v-btn
          tile
          small
          color="primary"
          class="mr-1"
          @click="manage"
        >
          <v-avatar size="25" tile>
            <v-img :src="iconadd"></v-img>
          </v-avatar>
          Tambah
        </v-btn> 
        <v-btn
          tile
          small
          color="success"
          class="mr-1"
          @click="renewdata"
        >
          <v-avatar size="25" tile>
            <v-img :src="iconrefresh"></v-img>
          </v-avatar>
          Refresh
        </v-btn> 
        <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
          no-gutters
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              tile
              small
              class="mr-1"
              color="error"                
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar size="25" tile>
                <v-img :src="iconfilter"></v-img>
              </v-avatar>
              Filter
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-menu
                      ref="menudari"
                      v-model="menudari"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dari"
                          label="Dari"
                          hint="YYYY-MM-DD"
                          persistent-hint 
                          v-bind="attrs" 
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dari"
                        no-title
                        @input="menudari = false"
                      ></v-date-picker>
                    </v-menu> 
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      ref="menusmp"
                      v-model="menusmp"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="sampai"
                          label="Sampai"
                          hint="YYYY-MM-DD"
                          persistent-hint
                          v-bind="attrs" 
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="sampai"
                        no-title
                        @input="menusmp = false"
                      ></v-date-picker>
                    </v-menu> 
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="srnobukti"
                      label="Nobukti"
                      hint="Search Nobukti"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" >
                    <v-text-field
                      v-model="srdesk"
                      label="Deskripsi"
                      hint="Search Data Deskripsi"
                      persistent-hint
                    ></v-text-field>
                  </v-col> 
                  <v-col cols="12"  sm="6" >
                    <v-autocomplete 
                      v-model="selectedcabang.nomor"
                      :items="datacabang"
                      item-text="desk"
                      item-value="nomor"  
                      clearable 
                      label="Data Cabang"  
                    ></v-autocomplete>
                  </v-col>  
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="caridata"
              >
                Search
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 
      </v-row>  
       
      <!-- tabel -->
      <div class="col">
        <v-card flat>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      No
                    </th>
                    <th class="text-left">
                      Tgl
                    </th>
                    <th class="text-left">
                      Nobukti
                    </th>  
                    <th class="text-left">
                      Customer
                    </th> 
                    <th class="text-left">
                      Deskripsi
                    </th>
                    <th class="text-left">
                      Debet
                    </th>
                    <th class="text-left">
                      Kredit
                    </th>
                    <th class="text-left">
                      Pengguna
                    </th>
                    <th class="text-left">
                      Cabang
                    </th>
                    <th class="text-left">
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in datatabel"
                    :key="item.no"
                  >
                    <td>{{ item.no }}</td>
                    <td>{{ item.tgl }}</td>
                    <td>{{ item.nobukti }}</td>  
                    <td>{{ item.customer }}</td>
                    <td>{{ item.desk }}</td> 
                    <td>{{ formatPrice(item.jumlahdebet) }}</td>
                    <td>{{ formatPrice(item.jumlahkredit) }}</td>
                    <td>{{ item.username }}</td>
                    <td>{{ item.cabang }}</td> 
                    <td>
                      <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </td> 
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-card>  
      </div>  
    </v-container> 
  </v-app>
</template>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from 'axios'; 

export default {
  components: {
    Vmenu
  },
  data() {
    return {
      menu: Constant.MENU_APP,
      dialog: false,
      dari: new Date().toISOString().substr(0, 10), 
      sampai: new Date().toISOString().substr(0, 10), 
      menudari: false,
      menusmp: false,
      iconedit: Constant.iconedit,
      iconhapus: Constant.icondelete,
      iconadd: Constant.iconadd,
      iconrefresh: Constant.iconrefresh,
      iconfilter: Constant.iconfilter, 
      srnobukti: "", 
      srdesk: "",    
      selecteduser: { nomor: "0", desk: " -Pilih Pengguna-" }, 
      datauser: [{nomor: "0", desk: "-Pilih Pengguna-"}],   
      selectedcabang: { nomor: "0", desk: " -Pilih Cabang-" }, 
      datacabang: [{nomor: "0", desk: "-Pilih Cabang-"}],   
      datatabel: [],    
    };
  },
  methods: {
    async getmaster(){
      this.loading = true; 
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP 
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            this.datauser = response.data.datauser
            this.datacabang = response.data.datacabang2  
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false; 
    },
    async getdata() {  
      this.loading = true; 
      const datakirim = {
        idlog: Constant.IDLOG_APP, 
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,  
        dari:this.dari,
        sampai:this.sampai,
        srnobukti: this.srnobukti,          
        sruser: this.selecteduser.nomor,        
        srdesk: this.srdesk,        
        srcabang: this.selectedcabang.nomor,   
      };   
     
      await axios
        .post(Constant.API_ADDR + "bayarp/daftarbayarp", datakirim)
        .then(response => {
          if (response.data.status === 102) 
          {
            this.datatabel = response.data.data;  
          }  
          else
          {
           if(response.data.status == 100)
           {
             alert(response.data.message)
             this.Constant.USER_APP = '';
             this.Constant.TOKEN_APP = ''; 
           } 
          }
        })
        .catch(errros => {
          console.log("error");
        }); 
      this.loading = false;  
    },
    editItem(item) {    
      this.$router.push({ name: "Pembayaran Penjualan Manage", 
        params: { Id: item.id, Jenis:'editdata', 
        idcabang:item.idcabang, tgl:item.tgl, nobukti:item.nobukti, 
        idsalesman:item.idsalesman, idcustomer:item.idcustomer, jumlahdebet: item.jumlahdebet,
        jumlahkredit: item.jumlahkredit, desk:item.desk, 
        datatabeld:item.datad, datatabele:item.datae }      
      });
    },
    deleteItem(item) {
      this.loading = true;    
      // kirim data
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP,  
        idbayarh: item.id,
        idcabang: item.idcabang
      };  
      axios
        .post(Constant.API_ADDR + "bayarp/hapusbayarp", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          { 
            alert('sukses menghapus data')
            this.renewdata()  
          }
          else
          {
            alert(response.data.message)
          }
        })
        .catch((errros) => {
          console.log("error");
        });  
      this.loading = false;  
    },    
    renewdata() 
    { 
      this.getdata();
    },
    manage()
    {  
      if (Constant.USER_APP != "" && Constant.TOKEN_APP != "") 
      {  
        this.$router.push({ name: "Pembayaran Penjualan Manage", 
          params: { Id: -1, Jenis:'tambahdata', 
          idcabang:0, tgl:new Date().toISOString().substr(0, 10), nobukti:'', 
          idsalesman:0, idcustomer:0, jumlahdebet: 0,
          jumlahkredit: 0, desk:'', datatabeld:[], datatabele:[] }      
        });
      
      } 
    }, 
    caridata()
    {
      this.dialog = false;
      this.getdata();
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  },
  created() 
  {
    Constant.IMENU = 'Pembayaran Penjualan';
    this.dari = Constant.STARTDATE;
    this.getmaster();
    this.getdata();
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>
