<template>
  <v-app id="inspire">
    <Vmenu />    
    <v-card flat>
      <v-card-title>
        {{ menu }}  
      </v-card-title>
      <!-- tabel -->
      <div class="col mt-n4">
        <v-card flat>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" width="20px">
                      No
                    </th>
                    <th class="text-left">
                      Nama Laporan
                    </th>
                    <th class="text-left">
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr 
                    v-for="item in datatabel"
                    :key="item.id"
                  > 
                    <td>{{ item.id }}</td> 
                    <td>{{ item.nama }}</td> 
                    <td> 
                      <v-btn small icon class="ma-1" color="teal">
                        <v-avatar size="30" @click="editItem(item)" tile>
                          <v-img :src="iconprinter"></v-img>
                        </v-avatar>
                       </v-btn> 
                    </td> 
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-card>  
      </div>  
    </v-card>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                  ref="menudari"
                  v-model="menudari"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dari"
                      label="Periode"
                      hint="YYYY-MM"
                      persistent-hint 
                      v-bind="attrs" 
                      v-on="on"
                      class="pa-1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dari"
                    type="month"
                    no-title
                    @input="menudari = false"
                  ></v-date-picker>
                </v-menu> 
              </v-col> 
              <v-col cols="12" sm="6" >
                <v-autocomplete 
                  v-model="selectedcabang.nomor"
                  :items="datacabang"
                  item-text="desk"
                  item-value="nomor"  
                  clearable 
                  label="Data Cabang"  
                  ref="refcabang"
                  class="pa-1" 
                ></v-autocomplete>
              </v-col> 
              <v-col cols="12" sm="6" >
                <v-autocomplete 
                  v-model="field"
                  :items="fields" 
                  class="pa-1"
                  label="Berdasarkan"
                ></v-autocomplete> 
              </v-col>
              <v-col cols="12" sm="6" >
                <v-autocomplete 
                  v-model="kondisi"
                  :items="kondisii" 
                  class="pa-1"
                  label="Kondisi"
                ></v-autocomplete>  
              </v-col>
              <v-col cols="12" >
                <v-text-field
                  v-model="keterangan"
                  label="Deskripsi"
                  hint="Deskripsi"
                  persistent-hint
                ></v-text-field>
              </v-col>  
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="tutup"
          >
            Close
          </v-btn>
          <v-btn
            :loading="loadcetak"
            :disabled="loadcetak"
            color="blue darken-1"
            text
            @click="cetakdata"
          >
            Cetak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     
     
    
  </v-app>
</template>

<script>
import Vmenu from "@/components/layout/Top.vue";
import Constant from "@/components/Constant.vue";
import axios from 'axios'; 

export default {
  components: {
    Vmenu
  },
  data() {
    return {
      menu: Constant.MENU_APP,   
      dialog: false,
      menudari: false,
      dari: new Date().toISOString().substr(0, 7),   
      iconprinter: Constant.iconprinter, 
      selectedcabang: { nomor: "0", desk: "-Pilih Cabang-" }, 
      datacabang: [{nomor: "0", desk: "-Pilih Cabang-"}],
      datatabel: [ 
          {id:1, nama:'Pengiriman Penjualan Per Nota'},
          {id:2, nama:'Pengiriman Penjualan Per Pelanggan'},    
          {id:3, nama:'Pengiriman Penjualan Per Salesman'},     
          {id:4, nama:'Pengiriman Penjualan Per Tanggal'},       
          {id:5, nama:'Pengiriman Penjualan Per Barang'},       
          {id:6, nama:'Pengiriman Penjualan Per Pelanggan & Nota'},       
          {id:7, nama:'Pengiriman Penjualan Per Salesman & Nota'},       
          {id:8, nama:'Pengiriman Penjualan Per Tanggal & Nota'},       
          {id:9, nama:'Pengiriman Penjualan Per Pelanggan & Barang'},       
          {id:10, nama:'Pengiriman Penjualan Per Barang & Pelanggan'},       
          {id:11, nama:'Pengiriman Penjualan Per Salesman & Barang'},       
          {id:12, nama:'Pengiriman Penjualan Per Salesman, Barang & Pelanggan'},       
          {id:13, nama:'Pengiriman Penjualan Per Tanggal & Barang'},       
          {id:14, nama:'Pengiriman Penjualan Per Salesman & Tanggal'},       
          {id:15, nama:'Pengiriman Penjualan Per Salesman & Kelompok Barang'},       
          {id:16, nama:'Pengiriman Penjualan Per Nota & Barang'},       
          {id:17, nama:'Pengiriman Penjualan Per Pelanggan, Nota & Barang'},       
          {id:18, nama:'Pengiriman Penjualan Per Salesman, Nota & Barang'},       
          {id:19, nama:'Pengiriman Penjualan Per Tanggal, Nota & Barang'},       
          {id:20, nama:'Pengiriman Penjualan Per Salesman, Pelanggan & Barang'},     
        ], 
      loading: true,
      field:'',
      fields:[],
      kondisi:'',
      kondisii:['Sama Dengan', 'Tidak Sama', 'Lebih Dari', 'Kurang Dari', 
      'Lebih Dari Sama Dengan', 'Kurang Dari Sama Dengan' ],
      keterangan:'',
      jenis:'',
      loadcetak:false
    };
  },
  methods: { 
    async editItem(item) 
    {  
      this.fields = []
      this.dialog = true
      this.loading = true 
      this.jenis = item.nama
      const datakirim = {
        idlog: Constant.IDLOG_APP, 
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        branch: Constant.BRANCH_APP,
        jenis: item.nama
      };   
     
      await axios
        .post(Constant.API_ADDR + "laporanpengiriman/daftarfield", datakirim)
        .then(response => {
          if (response.data.status === 102) 
          {
            this.fields = response.data.data;  
          }  
          else
          {
           if(response.data.status == 100)
           {
             alert(response.data.message)
             this.Constant.USER_APP = '';
             this.Constant.TOKEN_APP = ''; 
           } 
          }
        })
        .catch(errros => {
          console.log("error");
        }); 
      this.loading = false;  

    },
    cetakdata() { 
      this.loadcetak = true;  
      if(this.jenis != '') 
      {
        // kirim data
        const datakirim = {
          user: Constant.USER_APP,
          token: Constant.TOKEN_APP,
          idlog: Constant.IDLOG_APP,  
          jenis: this.jenis,  
          aksi: 'PDF',  
          periode: this.dari.replace("-",""),  
          pfilter1: 'SEMUA',  
          pfilter2: this.field,  
          pfilter3: this.kondisi,  
          pfilter4: this.keterangan,  
          cabang: this.selectedcabang.nomor
        };  
        axios
        .post(Constant.API_ADDR + "laporanpengiriman/request", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          {
            this.keterangan = ''
            this.fields = ''
            this.fields = []
            this.dialog = false
            this.loadcetak = false  
            this.jenis = '' 
            window.open(Constant.API_ADDR + "printpreviewlappengiriman/cetak/" + response.data.data)            
          }
          else
          { 
            alert(response.data.message)
            this.loadcetak = false;  
          }
        })
        .catch((errros) => {
          console.log("error");
          this.loadcetak = false;  
        });   
      } 
      this.loadcetak = false;  
      this.dialog = false
    },
    tutup(){
      this.keterangan = ''
      this.field  = ''
      this.fields = []
      this.dialog = false 
    },
    async getmaster(){
      this.loading = true; 
      const datakirim = {
        user: Constant.USER_APP,
        token: Constant.TOKEN_APP,
        idlog: Constant.IDLOG_APP, 
        branch: Constant.BRANCH_APP
      };  
      await axios
        .post(Constant.API_ADDR + "api/datamaster", datakirim)
        .then((response) => {   
          if(response.data.status == 102)
          {  
            this.datacabang = response.data.datacabang2 
          }
        })
        .catch((errros) => {
          console.log("error");
        });
      this.loading = false; 
    }, 
  },
  created() 
  {
    Constant.IMENU = 'L. Pengiriman Penjualan';
    this.getmaster()
    // this.dari = Constant.STARTDATE; 
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>
